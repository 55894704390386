import React, { useMemo, useState } from 'react'
import type { BidInfo } from '@/types/api_types'
import { Box, Typography } from '@mui/material'
import NiftySlider from '@/elements/src/NiftySlider'
import { Money } from '@/elements/src'
import useSimulatedBids from './_hooks/useSimulatedBids'

export default function RankingSimulator({
  sortedBids,
  lowestDisplayRank,
  defaultRank = 1,
  tickStyle,
}: RankingSimulatorProps) {
  const [selectedRank, setSelectedRank] = useState(defaultRank || 1)
  const { sortedSimulatedBids, displayCurrency } = useSimulatedBids(
    sortedBids,
    lowestDisplayRank,
  )
  const currentSimulatedBid = sortedSimulatedBids[selectedRank - 1]
  const highestRank = Math.min(sortedSimulatedBids.length, 1)
  const lowestRank = sortedSimulatedBids.length

  const showTicksAndOrLabels = useMemo(() => {
    switch (tickStyle) {
      case 'labels':
        return sortedSimulatedBids
      case 'ticks':
        return true
    }
    return false
  }, [tickStyle, sortedSimulatedBids])

  const handleChange = (_: Event, newRank: number | number[]) => {
    if (typeof newRank === 'number')
      setSelectedRank(
        // keep the rank in the range [1, num bids]
        Math.min(Math.max(1, newRank), sortedSimulatedBids.length),
      )
    else throw new Error(`RankingSimulator doesn't support ranges of ranks`)
  }

  return (
    <Box>
      <NiftySlider
        defaultValue={selectedRank}
        onChange={handleChange}
        marks={showTicksAndOrLabels}
        step={tickStyle === 'labels' ? null : 1}
        track={false}
        min={highestRank}
        max={lowestRank}
        aria-label='simulated rank'
        valueLabelDisplay='on'
        getAriaValueText={(v) => `#${v}`}
        valueLabelFormat={(v) => `#${v}`}
      />
      <Typography sx={{ color: '#676868', opacity: 0.75, fontWeight: 400 }}>
        To be ranked{' '}
        <span style={{ color: '#010304', fontWeight: 600 }}>
          {currentSimulatedBid.rankLabel}
        </span>{' '}
        place a bid greater than{' '}
        <span style={{ color: '#010304', fontWeight: 600 }}>
          <Money
            value={[currentSimulatedBid.amount, displayCurrency]}
            convertFromCents={false}
          />
        </span>
        {'.'}
      </Typography>
    </Box>
  )
}

interface RankingSimulatorProps {
  sortedBids: BidInfo[]
  lowestDisplayRank: number
  defaultRank?: number
  tickStyle?: 'ticks' | 'labels'
}
