/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Typography, Grid, ListItemButton } from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import Skeleton from '@mui/material/Skeleton'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import CardHeader from '@mui/material/CardHeader'
import { Avatar, colors, theme } from '@/elements/src'
import buildSkeletonArray from '@/utils/build-skeleton-array'
import useSearch from '@/hooks/useSearch'
import { IndexTypeEnum, SearchResultItem } from '@/types/search'
import { setRecentSearch } from './search-storage'

const DEFAULT_SKELETON_ITEMS = 2
const skeletonItems = buildSkeletonArray(DEFAULT_SKELETON_ITEMS)
const MAX_RESULTS = 20

const FLAG_DROP_ROUTE =
  process.env.NEXT_PUBLIC_REACT_APP_FLAG_DROP_ROUTE === 'true'

enum FilterName {
  People = 'People',
  Creators = 'Creators',
  Publishers = 'Publishers',
}

const filterNameMap = {
  [FilterName.People]: 'User',
  [FilterName.Creators]: 'Creator',
  [FilterName.Publishers]: 'Publisher',
}

interface PeopleResultListProps {
  query: string
  artist?: boolean
  filterName: keyof typeof FilterName
  handleOnClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    path: string,
  ) => void
}

const PeopleResultList = ({
  query,
  handleOnClick,
  filterName,
  artist,
}: PeopleResultListProps) => {
  const filters =
    filterName !== FilterName.Publishers
      ? { filters: { is_creator: !!artist } }
      : {}
  const typeSearch =
    filterName !== FilterName.Publishers
      ? IndexTypeEnum.USERS
      : IndexTypeEnum.PUBLISHER

  const {
    flattedItems: results,
    query: { isLoading },
  } = useSearch(typeSearch, {
    query,
    page: 1,
    page_size: MAX_RESULTS,
    tokenizer: 'autocomplete',
    ...filters,
  })

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: SearchResultItem,
  ) => {
    const path =
      filterName === FilterName.Publishers || artist
        ? `/${item?.slug}`
        : `/@${item?.slug}`

    setRecentSearch({ item, path, type: filterNameMap[filterName] })
    handleOnClick(event, path)
  }

  return (
    <List
      component='div'
      sx={{
        margin: '0 6px',
        background: 'white',
        color: 'black',
      }}
    >
      <ListSubheader color='inherit'>
        <Typography variant='h4' gutterBottom sx={{ padding: '0 0 10px' }}>
          {filterName} ({results?.length})
        </Typography>
      </ListSubheader>
      {isLoading
        ? skeletonItems.map((_, index) => (
            <ListItemButton key={index} sx={{ paddingRight: 0 }}>
              <Card
                elevation={0}
                sx={{
                  width: '100%',
                  borderRadius: 0,
                  background: 'none',
                }}
              >
                <CardHeader
                  sx={{
                    padding: 0,
                  }}
                  avatar={
                    <Skeleton
                      animation='wave'
                      variant='circular'
                      width={40}
                      height={40}
                    />
                  }
                  title={
                    <Skeleton
                      animation='wave'
                      height={10}
                      width='80%'
                      style={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    <Skeleton animation='wave' height={10} width='40%' />
                  }
                />
              </Card>
            </ListItemButton>
          ))
        : results?.map((item: SearchResultItem) => (
            <ListItemButton
              key={item.id}
              sx={{
                paddingRight: 0,
              }}
              onClick={(event) => handleClick(event, item)}
            >
              <Card
                elevation={0}
                sx={{
                  width: '100%',
                  borderRadius: 0,
                  background: 'none',
                }}
              >
                <CardHeader
                  sx={{
                    padding: 0,
                  }}
                  avatar={
                    <Avatar
                      src={item.profile_picture_url}
                      sx={{ height: 40, width: 40, marginRight: '8px' }}
                    />
                  }
                  title={
                    <Typography variant='body1' color={colors.niftyBlack}>
                      {item.display_name}
                    </Typography>
                  }
                  subheader={
                    <Typography variant='subtitle1' color={colors.niftyBlack}>
                      {`@${item.slug}`}
                    </Typography>
                  }
                />
              </Card>
            </ListItemButton>
          ))}
      {!isLoading && !results?.length && (
        <Box>
          <Typography
            variant='body2'
            color={colors.niftyBlack}
            style={{ fontStyle: 'italic', padding: '0 16px' }}
          >
            No {filterName.toLowerCase()} found
          </Typography>
        </Box>
      )}
    </List>
  )
}

interface CollectionResultListProps {
  query: string
  handleOnClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    path: string,
  ) => void
}

const CollectionResultList = ({
  query,
  handleOnClick,
}: CollectionResultListProps) => {
  const {
    flattedItems: items,
    query: { isLoading },
  } = useSearch(IndexTypeEnum.COLLECTIONS, {
    query,
    page: 1,
    page_size: MAX_RESULTS,
    tokenizer: 'autocomplete',
  })

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: SearchResultItem,
  ) => {
    const collectionPath = FLAG_DROP_ROUTE
      ? `/collections/${item.slug}`
      : `/marketplace/collection/${item.contract_address}`
    const isCollectible = item.project_category === 'collectible'

    const path = isCollectible
      ? `/marketplace/collectible/${item.contract_address}`
      : collectionPath
    setRecentSearch({ item, path, type: 'Collection' })
    handleOnClick(event, path)
  }

  return (
    <List
      component='div'
      sx={{
        margin: '0 6px',
        background: 'white',
        color: 'black',
      }}
    >
      <ListSubheader color='inherit'>
        <Typography variant='h4' gutterBottom sx={{ padding: '0 0 10px' }}>
          Collections ({items?.length})
        </Typography>
      </ListSubheader>
      {isLoading && !items?.length
        ? skeletonItems.map((_: any, index: React.Key | null | undefined) => (
            <ListItemButton
              key={index}
              sx={{
                paddingRight: 0,
              }}
            >
              <Card
                elevation={0}
                sx={{
                  width: '100%',
                  borderRadius: 0,
                  background: 'none',
                }}
              >
                <CardHeader
                  sx={{
                    padding: 0,
                  }}
                  avatar={
                    <Skeleton
                      animation='wave'
                      variant='rectangular'
                      width={80}
                      height={80}
                      sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '16px',
                        [theme.breakpoints.down('lg')]: {
                          width: '64px',
                          height: '64px',
                        },
                      }}
                    />
                  }
                  title={
                    <Skeleton
                      animation='wave'
                      height={10}
                      width='80%'
                      sx={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    <Skeleton animation='wave' height={10} width='40%' />
                  }
                />
              </Card>
            </ListItemButton>
          ))
        : items?.map((item: SearchResultItem) => (
            <ListItemButton
              key={item.id}
              sx={{
                paddingRight: 0,
              }}
              onClick={(event) => handleClick(event, item)}
            >
              <Card
                elevation={0}
                sx={{
                  width: '100%',
                  borderRadius: 0,
                  background: 'none',
                }}
              >
                <CardHeader
                  sx={{
                    padding: 0,
                  }}
                  avatar={
                    <Avatar
                      sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: '16px',
                        [theme.breakpoints.down('lg')]: {
                          width: '64px',
                          height: '64px',
                        },
                      }}
                      src={item.project_icon}
                    />
                  }
                  title={
                    <Typography variant='body1' color={colors.niftyBlack}>
                      {item.store_name}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      variant='subtitle1'
                      color={colors.niftyBlack}
                    >{`@${item.slug}`}</Typography>
                  }
                />
              </Card>
            </ListItemButton>
          ))}
      {!isLoading && !items?.length && (
        <Box>
          <Typography
            variant='body2'
            color={colors.niftyBlack}
            style={{ fontStyle: 'italic', padding: '0 16px' }}
          >
            No collections found
          </Typography>
        </Box>
      )}
    </List>
  )
}
interface SearchResultsProps {
  query: string
  onClickResult: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    path: string,
  ) => void
}

const SearchResults = ({ query, onClickResult }: SearchResultsProps) => {
  if (!query) return <div />
  return (
    <List>
      <Grid container>
        <Grid item xs={12} lg={4}>
          <PeopleResultList
            artist
            key='artist'
            query={query}
            handleOnClick={onClickResult}
            filterName={FilterName.Creators}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <PeopleResultList
            key='user'
            query={query}
            handleOnClick={onClickResult}
            filterName={FilterName.People}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CollectionResultList query={query} handleOnClick={onClickResult} />
        </Grid>
      </Grid>
    </List>
  )
}

export default SearchResults
