'use client'
import { useCallback, useState } from 'react'
import CheckoutModal from '@/app/(ng-core)/checkout/_components/CheckoutModal'
import {
  CheckoutModalContext,
  type OpenCheckoutModalConfig,
} from './CheckoutModalContext'

export function CheckoutModalProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalConfig, setModalConfig] = useState<OpenCheckoutModalConfig>()
  const openCheckoutModal = useCallback(
    (config: OpenCheckoutModalConfig) => {
      setIsOpen(true)
      setModalConfig(config)
    },
    [setIsOpen, setModalConfig],
  )

  const closeCheckoutModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  return (
    <CheckoutModalContext.Provider
      value={{
        isOpen,
        openCheckoutModal,
        closeCheckoutModal,
      }}
    >
      {isOpen && modalConfig ? (
        <CheckoutModal
          {...modalConfig}
          isOpen={isOpen}
          onClose={closeCheckoutModal}
        />
      ) : null}
      {children}
    </CheckoutModalContext.Provider>
  )
}
