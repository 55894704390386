import useDialog from '@/hooks/useDialog'
import { LoadingSpinner } from '@/elements/src'
import { useEffect, useState } from 'react'
import useNiftyType from '@/hooks/useNiftyType'
import {
  useCreatePrimaryMarketPurchaseIntent,
  usePrimaryMarketPurchaseIntent,
} from '@/hooks/usePurchaseIntent'
import DrawingCheckout, { DrawingCheckoutProps } from './DrawingCheckout'

type ContainerProps = Omit<DrawingCheckoutProps, 'openDialog'>

const DrawingCheckoutContainer = ({
  contractAddress,
  niftyType,
  purchaseIntentId,
  closeModal,
  onPaymentSuccess,
}: ContainerProps) => {
  const [createdPurchaseIntent, setCreatedPurchaseIntent] = useState(false)
  const { data: nifty, isLoading: isNiftyLoading } = useNiftyType({
    contractAddress,
    niftyType,
  })
  const { createPurchaseIntent } = useCreatePrimaryMarketPurchaseIntent({
    contractAddress,
    niftyType,
  })

  const { purchaseIntent, isLoading } = usePrimaryMarketPurchaseIntent(
    purchaseIntentId,
    { dropType: nifty?.primary_sale_type },
  )
  const { RenderDialog, openDialog } = useDialog()

  useEffect(() => {
    // Don't create a new purchase intent if we already have one or we're still loading
    if (
      createdPurchaseIntent ||
      !nifty ||
      ((!!purchaseIntent || isLoading) && !!purchaseIntentId)
    )
      return
    setCreatedPurchaseIntent(true)
    createPurchaseIntent({
      contractAddress,
      niftyType,
      nifty_builder: nifty.id,
      requested_quantity: 1,
      dropType: nifty?.primary_sale_type,
    })
  }, [
    purchaseIntent,
    isLoading,
    nifty,
    createPurchaseIntent,
    purchaseIntentId,
    createdPurchaseIntent,
    contractAddress,
    niftyType,
  ])

  return !purchaseIntentId || isNiftyLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <DrawingCheckout
        openDialog={openDialog}
        contractAddress={contractAddress}
        niftyType={niftyType}
        purchaseIntentId={purchaseIntentId}
        closeModal={closeModal}
        onPaymentSuccess={onPaymentSuccess}
      />
      <RenderDialog />
    </>
  )
}

export default DrawingCheckoutContainer
