'use client'

import Script from 'next/script'

// Loading strategy is set to afterInteractive: Load early but after some hydration on the page occurs.
const MicrosoftClarity = () => {
  return (
    <Script
      id='ng-3rdParty--MsClarity'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_REACT_APP_MICROSOFT_CLARITY}");
        `,
      }}
    />
  )
}

export default MicrosoftClarity
