export const OFFER_STATUS_TITLES = {
  TRY_AGAIN: 'Try again 🤔',
  MAYBE_NEXT_TIME: 'Maybe next time 🥲',
  OFFER_ACCEPTED: 'Offer accepted! 🎉',
  OFFER_SENT: 'Offer sent! 🎉',
}

export const OFFER_STATUS_DESCRIPTIONS = {
  SOMETHING_WENT_WRONG: 'Something went wrong... please try that again.',
  NOT_ACCEPTED: 'Your offer was not accepted by the owner.',
  DEFAULT:
    'Your offer has been sent to the owner. They have X time to accept your offer; if not accepted, the offer will expire.',
}

export const OFFER_DELIVERY_STEP_NAMES = {
  DEFAULT: 'NFT Delivered',
}

export const OFFER_DELIVERY_STEP_DESCRIPTIONS = {
  NFT_SENT: 'NFT has been sent to your Nifty Gateway wallet.',
  DEFAULT: 'NFT will be sent to your Nifty Gateway wallet.',
}

export const OFFER_PENDING_STEP_DESCRIPTIONS = {
  PAYMENT_FAILED: 'We were not able to process your payment.',
  CANCELED: 'You have not been charged.',
  BEEN_CHARGED: 'You have been charged',
  ON_SELECTED_PAYMENT_METHOD: 'on your selected payment method.',
  DEFAULT: 'You will not be charged unless the owner accepts your offer.',
}

export const OFFER_PENDING_STEP_NAMES = {
  PAYMENT_FAILED: 'Payment Failed',
  DECLINED: 'Offer Declined',
  ACCEPTED: 'Offer Accepted',
  PENDING: 'Offer Pending',
}

export const ACTIONS_TEXT = {
  CONTINUE: 'Keep Browing',
  GO_TO_PROFILE: 'View Profile',
}
