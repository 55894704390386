import { DetectorOptions } from './types'

export default {
  detect(options: DetectorOptions) {
    let found

    if (typeof window !== 'undefined') {
      let searchString = window.location.search

      if (
        !window.location.search &&
        window.location.hash &&
        window.location.hash.indexOf('?') > -1
      ) {
        searchString = window.location.hash.substring(
          window.location.hash.indexOf('?'),
        )
      }

      const query = searchString.substring(1)
      const params = query.split('&')

      for (let i = 0; i < params.length; i += 1) {
        const pos = params[i].indexOf('=')

        if (pos > 0) {
          const key = params[i].substring(0, pos)

          if (key === options.lookupQuerystring) {
            found = params[i].substring(pos + 1)
          }
        }
      }
    }

    return found
  },
}
