import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'

import { EnabledPaymentOptionsParams, PaymentOption } from '@/types/api_types'
import useDefaultPaymentMethod from './useDefaultPaymentMethod'
import { PaymentMethod } from '@/types/payment-types'
import { NiftyDestination } from '@/types/payment'
import { useQuery } from '@tanstack/react-query'
import { getEnabledPaymentOptions } from '@/api/payments'

const transformResponse = (
  apiOptions: PaymentOption[],
  userDefaultPaymentMethod: PaymentMethod,
) => {
  const sanitizedOptions = apiOptions.map(
    ({ niftyDeliveryDestination, paymentType }) => ({
      niftyDeliveryDestination,
      paymentType,
    }),
  )
  const paymentOptions = uniqWith(sanitizedOptions, isEqual)

  const enabledPaymentMethods = Array.from(
    paymentOptions.reduce(
      (acc, { paymentType }) => acc.add(paymentType),
      new Set<PaymentMethod>(),
    ),
  )

  const getDestinationOptions = (paymentMethod: PaymentMethod) => {
    const deliveryOptions = paymentOptions
      .filter((o) => o.paymentType === paymentMethod)
      .map((o) => o.niftyDeliveryDestination)

    return {
      deliveryOptions,
      allowOmnibus: deliveryOptions.includes(NiftyDestination.NIFTY_GATEWAY),
      allowExternalWallet: deliveryOptions.includes(
        NiftyDestination.EXTERNAL_WALLET,
      ),
    }
  }

  const initialPaymentMethod = enabledPaymentMethods.includes(
    userDefaultPaymentMethod,
  )
    ? userDefaultPaymentMethod
    : enabledPaymentMethods[0]

  return {
    paymentOptions,
    enabledPaymentMethods,
    getDestinationOptions,
    initialPaymentMethod,
  }
}

export const usePaymentOptions = (
  purchaseIntentId?: Nullable<string>,
  params?: EnabledPaymentOptionsParams,
) => {
  const { defaultPaymentMethod } = useDefaultPaymentMethod()
  const { data, ...rest } = useQuery({
    queryKey: ['enabledPaymentOptions', purchaseIntentId, params],
    queryFn: () =>
      getEnabledPaymentOptions({
        purchase_intent_id: purchaseIntentId ?? undefined,
        ...params,
      }).then((res) => res.results),
    select: (paymentOptions) =>
      transformResponse(paymentOptions, defaultPaymentMethod),
    staleTime: Infinity,
  })

  return {
    ...data,
    ...rest,
  }
}
