const URL = {
  AA_SPACE_MISSIONS: '/awkward-astronauts/space-missions',
  ABOUT: '/about',
  ACCOUNT: '/account',
  ARTIST_SIGNING: '/contracts/:action?',
  AUCTIONBID: '/auctionbid',
  AUTHORIZE: '/authorize',
  AWKWARD_ASTRONAUTS: '/awkward-astronauts/:section?',
  AWKWARD_ASTRONAUTS_HOME: '/awkward-astronauts',
  BECOME_CREATOR: '/become-creator',
  BUY_PACKPAGE: '/buypackpage',
  BUY_PAGE: '/buypage',
  CHECKOUT: '/buypage',
  COLLECTION: '/collections/:collectionSlug',
  COLLECTION_LEGACY: '/legacy/collections/:collectionSlug',
  COLLECTION_NIFTY: '/collections/:address/:id',
  COLLECTIONS: '/collections',
  CONNECTGEMINI: '/connect-gemini',
  CURATED_COLLECTIONS: '/collections-curated',
  CREATOR_PROCEEDS_TAX_INFO: '/creator-proceeds/tax-info',
  CREATOR_PROCEEDS_W8_UPLOAD: '/creator-proceeds/w8-upload',
  DASHBOARD: '/dashboard',
  DEPOSIT: '/deposit',
  DISCOVERY: '/discover',
  DISPLAY: '/view-displays',
  DRAWINGENTRY: '/enterdrawing',
  DROPS: '/drops',
  FORGOT_PASSWORD: '/forgot-password',
  GAS_SAVINGS: '/gas-savings',
  HOME: '/',
  LISTING: '/listing',
  LISTING_CHECKOUT: '/listing-checkout/:contractAddress/:tokenId',
  LISTING_CHECKOUT_CONFIRMATION:
    '/listing-checkout/:contractAddress/:tokenId/confirm',
  LOGIN: '/login',
  MAKE_GLOBAL_OFFER: '/makeglobaloffer',
  MAKE_OFFER: '/makeoffer',
  MARKETPLACE: '/marketplace',
  MARKETPLACE_ARTIST: '/:artistSlug',
  MARKETPLACE_COLLECTIBLE: '/marketplace/collectible/:contractAddress',
  MARKETPLACE_COLLECTION: '/marketplace/collection/:contractAddress',
  MARKETPLACE_COLLECTION_NIFTYTYPE:
    '/marketplace/collection/:contractAddress/:niftyType',
  MARKETPLACE_ITEM: '/marketplace/item/:contractAddress/:tokenId',
  MY_PROFILE: '/my-profile/:profileTab?',
  NIFTIES: '/nifties/:address/:niftyType',
  NIFTY_TRAITS_GENERATOR: '/niftytraitsgenerator',
  ONBOARDING: '/onboarding',
  PACKPURCHASE:
    '/buypackpage/?contractAddress=:address&niftyType=:id&primaryOrSecondary=primary',
  PREPAY: '/account/payment-methods/prePaid',
  PRIMARY_ITEM_DETAIL: '/itemdetail/primary/:contractAddress/:niftyType',
  PROFILE: '/profile/:username',
  PROFILEV2: '/@:username',
  PROMO: '/promos/:project',
  PUBLISHER_FEES_CHECKOUT: '/publish-drop/:collectionTemplateId',
  PUBLISHER_FEES_CHECKOUT_CONFIRMATION:
    '/publish-drop/:collectionTemplateId/confirm',
  RANKEDAUCTIONBID: '/rankedbid',
  RANKINGS: '/rankings',
  REFERRAL_SIGNUP: '/signup?referral=:artist',
  REFERRALS: '/pomp',
  REGISTER: '/signup',
  SEARCH: '/search',
  SIGNUP: '/signup',
  SILENTAUCTIONBID: '/silentauctionbid',
  SITEWIDEACTIVITY: '/sitewide-activity',
  SPACESTATION: '/awkward-astronauts/space-station',
  SPACEMISSIONS: '/awkward-astronauts/space-missions',
  EXPLORE: '/awkward-astronauts/explore',
  SPOTLIGHT: '/spotlight/:id',
  V2_CHECKOUT: '/checkout/:contractAddress/:niftyType',
  V2_CHECKOUT_CONFIRMATION: '/checkout/:contractAddress/:niftyType/confirm',
  VERIFIED_COLLECTIONS: '/collections-verified',
  VALIDATE_PROFILE: '/account/account/validate',
  VERIFY: '/account/verify',
  WHAT_IS_A_NIFTY: '/what-is-an-nft',
  WITHDRAW: '/withdraw/:contractAddress/:tokenId',
  WITHDRAW_CONFIRMATION: '/withdraw/:contractAddress/:tokenId/confirm',
  WALLET_PREFERENCES: '/account/wallet-preferences',
  PUBLISHERS_DETAILS: '/:slug',
  PUBLISHERS_V2: '/publisher',
  PUBLISHERS_DEPRECATED: '/publisher-deprecated',
  MARKETPLACE_PUBLISHER: '/marketplace/publisher/:publisherSlug',
  PUBLISHERS_INTRO: '/publisher-intro',
  COLLECTOR_APPRECIATION: '/collector-appreciation',
  MARKETPLACE_SAM_SPRATT: '/samspratt',
  MARQUEE_SAM_SPRATT: '/collections/sam-spratt-the-monument-game',
  MARQUEE_SAM_SPRATT_BIENNALE:
    '/collections/sam-spratt-the-monument-game-exhibition',
  MARQUEE_SAM_SPRATT_BIENNALE_COMMENT_ENTRY:
    '/__spratt_biennale__/station-comment-entry',
  MARQUEE_SAM_SPRATT_BIENNALE_DISPLAY_DOT:
    '/__spratt_biennale__/station-display-dot',
  MARQUEE_SAM_SPRATT_BIENNALE_DISPLAY_COMMENT:
    '/__spratt_biennale__/station-display-comment',
  MAINTENANCE_PAGE: '/maintenance',
  EXPLORE_PAGE: '/explore',
  EXPLORE_PAGE_SECTION: '/explore/:section',
  EXPLORE_SEARCH_PAGE: '/search',
  EXPLORE_SEARCH_PAGE_SECTION: '/search/:section',
}

const LEGACY_URL = {
  LEGACY: '/legacy',
  EMAIL: '/email',
  TERMS: '/termsofuse',
  PUBLISHER_TERMS: '/publisher-terms-of-service',
  PRIVACY_POLICY: '/privacypolicy',
  PROFILE: '/profile/:username',
  PRIMARY_ITEM_DETAIL: '/itemdetail/primary/:address/:id',
  SECONDARY_ITEM_DETAIL: '/itemdetail/secondary/:address/:id',
  PACK_DETAIL: '/packdetail',
  CASHOUTS: '/cashouts',
  RESET: '/reset',
  RESET_PASSWORD: '/reset-password',
  DEPOSIT: '/deposit',
  CHECK_AUTHENTICITY: '/check-authenticity',
  JSON: '/json',
}

const API_URL = {
  SPENDABLE_ETH: 'user/spendable-eth/withdraw/',
  CREATE_PREPAY_ADDRESS: 'user/spendable-eth/',
  AUCTION_BID: 'user/place-pm-auction-bid/',
  SILENT_AUCTION_BID: 'user/place-pm-silent-auction-bid/',
  PRIMARY_PURCHASE: 'user/purchase-unminted-nifty/',
  SECONDARY_PURCHASE: 'user/purchase-centralized-listing/',
  UPDATE_PAYMENT_PREFERENCE: 'user/update-payment-preference/',
  DRAWINGENTRY: 'drawing/enter/',
}

const SEARCH_URL = {
  PEOPLE: 'api/v2/indexes/users/search',
  PUBLISHERS: 'api/v2/indexes/publishers/search',
  COLLECTIONS: 'api/v2/indexes/collections/search',
}

const ENDPOINT = {
  DROPS: 'drops/open/',
  RECOMMENDATIONS: 'recommendations/',
  GET_LIVE_AUCTIONS: 'auctions/live/',
}

const BREAKPOINTS = {
  xs: 575,
  sm: 767,
  md: 991,
  lg: 1199,
  xl: Infinity,
}

const ANALYTICS_EVENTS = {
  DISPLAY_CURRENCY: (currency: any) => `Display Currency ${currency}`,
}

const ETHERSCAN_ADDRESS =
  process.env.NEXT_PUBLIC_REACT_APP_WEB3_CHAIN_ENVIRONMENT === 'production'
    ? 'https://etherscan.io/address/:address'
    : 'https://rinkeby.etherscan.io/address/:address'

const ETHERSCAN = {
  ADDRESS: ETHERSCAN_ADDRESS,
  GET_ADDRESS_TRANSACTIONS:
    'https://api-rinkeby.etherscan.io/api?module=account&action=txlist&address=:address&sort=desc',
}

const PAK_MERGE_ADDRESS =
  process.env.NEXT_PUBLIC_REACT_APP_WEB3_CHAIN_ENVIRONMENT === 'production'
    ? '0xc3f8a0f5841abff777d3eefa5047e8d413a1c9ab'
    : '0xb170323d7d77c0ad33632f6b02544025754d3236'

const PAK_OFFER_WARNING = 'IMPORTANT: This offer is for your entire mass.'

const MASTER_PROFILE_URL = 'master_nifty'

const MARKETPLACE_PAGE_SIZE = 20

const ITEM_HISTORY_SIZE = 10

const DISPLAY_CURRENCY = 'USD'

const NIFTY_HELP_URL = 'https://help.niftygateway.com/hc/en-us'

// vendor api endpoints
const ZENDESK_SUPPORT_URL = 'https://niftygateway.zendesk.com/hc/en-us'
const FAQ_URL =
  'https://niftygateway.zendesk.com/hc/en-us/sections/1500000146081-FAQ'

const NIFTY_IMAGE_URL =
  'https://media.niftygateway.com/image/upload/v1576344316/nifty-builder-images/kyhclu5quebqm4sit0he.png'

const APPLY_TO_CREATE_WITH_NIFTY = 'https://tommy337349.typeform.com/to/qAasuU'
const TERMS_OF_USE =
  'https://docs.google.com/document/d/1O_WrtwSezW1nl1evI-xjcK0PhWxXikN9RBIDmRuvjNQ'
const PRIVACY_POLICY =
  'https://docs.google.com/document/d/1wwGFnXVBOr5JmsTGjHmXOqGjmYM0f7CizAOZ83hTL_g'
const GUIDES = {
  HOME: 'https://niftygateway.wpcomstaging.com/',
  BLOG: 'https://www.blog.niftygateway.com/blog',
  CASHOUT:
    'https://niftygateway.wpcomstaging.com/2020/02/27/how-do-i-cash-out-the-balance-ive-made-from-selling-my-nifties-on-nifty-gateway/',
  DISPLAYING_NIFTIES:
    'https://medium.com/@pierce_85676/nifty-gateway-releases-nft-display-for-open-beta-2c84ce793a3c',
  PAYMENTS:
    'https://niftygateway.wpcomstaging.com/2020/02/27/what-are-the-different-payment-methods-i-can-use-to-buy-nifties-on-nifty-gateway/',
  SELLING:
    'https://niftygateway.wpcomstaging.com/2020/02/27/how-do-i-put-a-nifty-up-for-sale-on-nifty-gateway/',
  DEPOSIT_NIFTIES:
    'https://niftygateway.wpcomstaging.com/2020/02/27/how-can-i-deposit-a-nifty-from-an-external-wallet-into-nifty-gateway/',
  EXHIBITION:
    'https://niftygateway.wpcomstaging.com/2020/03/13/exhibitions-guide/',
  MISSING_NIFTIES_ON_DEPOSIT:
    'https://niftygateway.wpcomstaging.com/2020/03/13/why-are-my-nifties-not-showing-up-to-deposit-why-can-i-only-deposit-some-nifties/',
  WITHDRAWING_NIFTIES:
    'https://help.niftygateway.com/hc/en-us/articles/1500003337121-How-do-I-deposit-Nifties-from-an-external-wallet-',
  SECURITY:
    'https://www.gemini.com/security',
  CAREERS: 'https://www.gemini.com/careers/nifty-gateway',
  API: 'https://gemini-marketing.notion.site/Nifty-Gateway-OAuth-Public-API-e41b60ae3ebc440fb1d44fd18496f3f2',
  WHAT_IS_AN_NFT: 'https://www.gemini.com/cryptopedia/explore#nf-ts',
}

const POST_AUTH_REDIRECT_PARAM = 'post_auth_redirect_location'

const RECUR_POINT_NAME = 'recur'

const COUNTRY_CODES = [
  {
    value: 'AF',
    name: 'Afghanistan',
  },
  {
    value: 'AX',
    name: 'Åland Islands',
  },
  {
    value: 'AL',
    name: 'Albania',
  },
  {
    value: 'DZ',
    name: 'Algeria',
  },
  {
    value: 'AS',
    name: 'American Samoa',
  },
  {
    value: 'AD',
    name: 'Andorra',
  },
  {
    value: 'AO',
    name: 'Angola',
  },
  {
    value: 'AI',
    name: 'Anguilla',
  },
  {
    value: 'AQ',
    name: 'Antarctica',
  },
  {
    value: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    value: 'AR',
    name: 'Argentina',
  },
  {
    value: 'AM',
    name: 'Armenia',
  },
  {
    value: 'AW',
    name: 'Aruba',
  },
  {
    value: 'AU',
    name: 'Australia',
  },
  {
    value: 'AT',
    name: 'Austria',
  },
  {
    value: 'AZ',
    name: 'Azerbaijan',
  },
  {
    value: 'BS',
    name: 'Bahamas',
  },
  {
    value: 'BH',
    name: 'Bahrain',
  },
  {
    value: 'BD',
    name: 'Bangladesh',
  },
  {
    value: 'BB',
    name: 'Barbados',
  },
  {
    value: 'BY',
    name: 'Belarus',
  },
  {
    value: 'BE',
    name: 'Belgium',
  },
  {
    value: 'BZ',
    name: 'Belize',
  },
  {
    value: 'BJ',
    name: 'Benin',
  },
  {
    value: 'BM',
    name: 'Bermuda',
  },
  {
    value: 'BT',
    name: 'Bhutan',
  },
  {
    value: 'BO',
    name: 'Bolivia (Plurinational State of)',
  },
  {
    value: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    value: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    value: 'BW',
    name: 'Botswana',
  },
  {
    value: 'BV',
    name: 'Bouvet Island',
  },
  {
    value: 'BR',
    name: 'Brazil',
  },
  {
    value: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    value: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    value: 'BG',
    name: 'Bulgaria',
  },
  {
    value: 'BF',
    name: 'Burkina Faso',
  },
  {
    value: 'BI',
    name: 'Burundi',
  },
  {
    value: 'CV',
    name: 'Cabo Verde',
  },
  {
    value: 'KH',
    name: 'Cambodia',
  },
  {
    value: 'CM',
    name: 'Cameroon',
  },
  {
    value: 'CA',
    name: 'Canada',
  },
  {
    value: 'KY',
    name: 'Cayman Islands',
  },
  {
    value: 'CF',
    name: 'Central African Republic',
  },
  {
    value: 'TD',
    name: 'Chad',
  },
  {
    value: 'CL',
    name: 'Chile',
  },
  {
    value: 'CN',
    name: 'China',
  },
  {
    value: 'CX',
    name: 'Christmas Island',
  },
  {
    value: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    value: 'CO',
    name: 'Colombia',
  },
  {
    value: 'KM',
    name: 'Comoros',
  },
  {
    value: 'CG',
    name: 'Congo',
  },
  {
    value: 'CD',
    name: 'Congo, Democratic Republic of the',
  },
  {
    value: 'CK',
    name: 'Cook Islands',
  },
  {
    value: 'CR',
    name: 'Costa Rica',
  },
  {
    value: 'CI',
    name: "Côte d'Ivoire",
  },
  {
    value: 'HR',
    name: 'Croatia',
  },
  {
    value: 'CU',
    name: 'Cuba',
  },
  {
    value: 'CW',
    name: 'Curaçao',
  },
  {
    value: 'CY',
    name: 'Cyprus',
  },
  {
    value: 'CZ',
    name: 'Czechia',
  },
  {
    value: 'DK',
    name: 'Denmark',
  },
  {
    value: 'DJ',
    name: 'Djibouti',
  },
  {
    value: 'DM',
    name: 'Dominica',
  },
  {
    value: 'DO',
    name: 'Dominican Republic',
  },
  {
    value: 'EC',
    name: 'Ecuador',
  },
  {
    value: 'EG',
    name: 'Egypt',
  },
  {
    value: 'SV',
    name: 'El Salvador',
  },
  {
    value: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    value: 'ER',
    name: 'Eritrea',
  },
  {
    value: 'EE',
    name: 'Estonia',
  },
  {
    value: 'SZ',
    name: 'Eswatini',
  },
  {
    value: 'ET',
    name: 'Ethiopia',
  },
  {
    value: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    value: 'FO',
    name: 'Faroe Islands',
  },
  {
    value: 'FJ',
    name: 'Fiji',
  },
  {
    value: 'FI',
    name: 'Finland',
  },
  {
    value: 'FR',
    name: 'France',
  },
  {
    value: 'GF',
    name: 'French Guiana',
  },
  {
    value: 'PF',
    name: 'French Polynesia',
  },
  {
    value: 'TF',
    name: 'French Southern Territories',
  },
  {
    value: 'GA',
    name: 'Gabon',
  },
  {
    value: 'GM',
    name: 'Gambia',
  },
  {
    value: 'GE',
    name: 'Georgia',
  },
  {
    value: 'DE',
    name: 'Germany',
  },
  {
    value: 'GH',
    name: 'Ghana',
  },
  {
    value: 'GI',
    name: 'Gibraltar',
  },
  {
    value: 'GR',
    name: 'Greece',
  },
  {
    value: 'GL',
    name: 'Greenland',
  },
  {
    value: 'GD',
    name: 'Grenada',
  },
  {
    value: 'GP',
    name: 'Guadeloupe',
  },
  {
    value: 'GU',
    name: 'Guam',
  },
  {
    value: 'GT',
    name: 'Guatemala',
  },
  {
    value: 'GG',
    name: 'Guernsey',
  },
  {
    value: 'GN',
    name: 'Guinea',
  },
  {
    value: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    value: 'GY',
    name: 'Guyana',
  },
  {
    value: 'HT',
    name: 'Haiti',
  },
  {
    value: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    value: 'VA',
    name: 'Holy See',
  },
  {
    value: 'HN',
    name: 'Honduras',
  },
  {
    value: 'HK',
    name: 'Hong Kong',
  },
  {
    value: 'HU',
    name: 'Hungary',
  },
  {
    value: 'IS',
    name: 'Iceland',
  },
  {
    value: 'IN',
    name: 'India',
  },
  {
    value: 'ID',
    name: 'Indonesia',
  },
  {
    value: 'IR',
    name: 'Iran (Islamic Republic of)',
  },
  {
    value: 'IQ',
    name: 'Iraq',
  },
  {
    value: 'IE',
    name: 'Ireland',
  },
  {
    value: 'IM',
    name: 'Isle of Man',
  },
  {
    value: 'IL',
    name: 'Israel',
  },
  {
    value: 'IT',
    name: 'Italy',
  },
  {
    value: 'JM',
    name: 'Jamaica',
  },
  {
    value: 'JP',
    name: 'Japan',
  },
  {
    value: 'JE',
    name: 'Jersey',
  },
  {
    value: 'JO',
    name: 'Jordan',
  },
  {
    value: 'KZ',
    name: 'Kazakhstan',
  },
  {
    value: 'KE',
    name: 'Kenya',
  },
  {
    value: 'KI',
    name: 'Kiribati',
  },
  {
    value: 'KP',
    name: "Korea (Democratic People's Republic of)",
  },
  {
    value: 'KR',
    name: 'Korea, Republic of',
  },
  {
    value: 'KW',
    name: 'Kuwait',
  },
  {
    value: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    value: 'LA',
    name: "Lao People's Democratic Republic",
  },
  {
    value: 'LV',
    name: 'Latvia',
  },
  {
    value: 'LB',
    name: 'Lebanon',
  },
  {
    value: 'LS',
    name: 'Lesotho',
  },
  {
    value: 'LR',
    name: 'Liberia',
  },
  {
    value: 'LY',
    name: 'Libya',
  },
  {
    value: 'LI',
    name: 'Liechtenstein',
  },
  {
    value: 'LT',
    name: 'Lithuania',
  },
  {
    value: 'LU',
    name: 'Luxembourg',
  },
  {
    value: 'MO',
    name: 'Macao',
  },
  {
    value: 'MG',
    name: 'Madagascar',
  },
  {
    value: 'MW',
    name: 'Malawi',
  },
  {
    value: 'MY',
    name: 'Malaysia',
  },
  {
    value: 'MV',
    name: 'Maldives',
  },
  {
    value: 'ML',
    name: 'Mali',
  },
  {
    value: 'MT',
    name: 'Malta',
  },
  {
    value: 'MH',
    name: 'Marshall Islands',
  },
  {
    value: 'MQ',
    name: 'Martinique',
  },
  {
    value: 'MR',
    name: 'Mauritania',
  },
  {
    value: 'MU',
    name: 'Mauritius',
  },
  {
    value: 'YT',
    name: 'Mayotte',
  },
  {
    value: 'MX',
    name: 'Mexico',
  },
  {
    value: 'FM',
    name: 'Micronesia (Federated States of)',
  },
  {
    value: 'MD',
    name: 'Moldova, Republic of',
  },
  {
    value: 'MC',
    name: 'Monaco',
  },
  {
    value: 'MN',
    name: 'Mongolia',
  },
  {
    value: 'ME',
    name: 'Montenegro',
  },
  {
    value: 'MS',
    name: 'Montserrat',
  },
  {
    value: 'MA',
    name: 'Morocco',
  },
  {
    value: 'MZ',
    name: 'Mozambique',
  },
  {
    value: 'MM',
    name: 'Myanmar',
  },
  {
    value: 'NA',
    name: 'Namibia',
  },
  {
    value: 'NR',
    name: 'Nauru',
  },
  {
    value: 'NP',
    name: 'Nepal',
  },
  {
    value: 'NL',
    name: 'Netherlands',
  },
  {
    value: 'NC',
    name: 'New Caledonia',
  },
  {
    value: 'NZ',
    name: 'New Zealand',
  },
  {
    value: 'NI',
    name: 'Nicaragua',
  },
  {
    value: 'NE',
    name: 'Niger',
  },
  {
    value: 'NG',
    name: 'Nigeria',
  },
  {
    value: 'NU',
    name: 'Niue',
  },
  {
    value: 'NF',
    name: 'Norfolk Island',
  },
  {
    value: 'MK',
    name: 'North Macedonia',
  },
  {
    value: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    value: 'NO',
    name: 'Norway',
  },
  {
    value: 'OM',
    name: 'Oman',
  },
  {
    value: 'PK',
    name: 'Pakistan',
  },
  {
    value: 'PW',
    name: 'Palau',
  },
  {
    value: 'PS',
    name: 'Palestine, State of',
  },
  {
    value: 'PA',
    name: 'Panama',
  },
  {
    value: 'PG',
    name: 'Papua New Guinea',
  },
  {
    value: 'PY',
    name: 'Paraguay',
  },
  {
    value: 'PE',
    name: 'Peru',
  },
  {
    value: 'PH',
    name: 'Philippines',
  },
  {
    value: 'PN',
    name: 'Pitcairn',
  },
  {
    value: 'PL',
    name: 'Poland',
  },
  {
    value: 'PT',
    name: 'Portugal',
  },
  {
    value: 'PR',
    name: 'Puerto Rico',
  },
  {
    value: 'QA',
    name: 'Qatar',
  },
  {
    value: 'RE',
    name: 'Réunion',
  },
  {
    value: 'RO',
    name: 'Romania',
  },
  {
    value: 'RU',
    name: 'Russian Federation',
  },
  {
    value: 'RW',
    name: 'Rwanda',
  },
  {
    value: 'BL',
    name: 'Saint Barthélemy',
  },
  {
    value: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    value: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    value: 'LC',
    name: 'Saint Lucia',
  },
  {
    value: 'MF',
    name: 'Saint Martin (French part)',
  },
  {
    value: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    value: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'WS',
    name: 'Samoa',
  },
  {
    value: 'SM',
    name: 'San Marino',
  },
  {
    value: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    value: 'SA',
    name: 'Saudi Arabia',
  },
  {
    value: 'SN',
    name: 'Senegal',
  },
  {
    value: 'RS',
    name: 'Serbia',
  },
  {
    value: 'SC',
    name: 'Seychelles',
  },
  {
    value: 'SL',
    name: 'Sierra Leone',
  },
  {
    value: 'SG',
    name: 'Singapore',
  },
  {
    value: 'SX',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    value: 'SK',
    name: 'Slovakia',
  },
  {
    value: 'SI',
    name: 'Slovenia',
  },
  {
    value: 'SB',
    name: 'Solomon Islands',
  },
  {
    value: 'SO',
    name: 'Somalia',
  },
  {
    value: 'ZA',
    name: 'South Africa',
  },
  {
    value: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: 'SS',
    name: 'South Sudan',
  },
  {
    value: 'ES',
    name: 'Spain',
  },
  {
    value: 'LK',
    name: 'Sri Lanka',
  },
  {
    value: 'SD',
    name: 'Sudan',
  },
  {
    value: 'SR',
    name: 'Suriname',
  },
  {
    value: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    value: 'SE',
    name: 'Sweden',
  },
  {
    value: 'CH',
    name: 'Switzerland',
  },
  {
    value: 'SY',
    name: 'Syrian Arab Republic',
  },
  {
    value: 'TW',
    name: 'Taiwan, Province of China',
  },
  {
    value: 'TJ',
    name: 'Tajikistan',
  },
  {
    value: 'TZ',
    name: 'Tanzania, United Republic of',
  },
  {
    value: 'TH',
    name: 'Thailand',
  },
  {
    value: 'TL',
    name: 'Timor-Leste',
  },
  {
    value: 'TG',
    name: 'Togo',
  },
  {
    value: 'TK',
    name: 'Tokelau',
  },
  {
    value: 'TO',
    name: 'Tonga',
  },
  {
    value: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    value: 'TN',
    name: 'Tunisia',
  },
  {
    value: 'TR',
    name: 'Turkey',
  },
  {
    value: 'TM',
    name: 'Turkmenistan',
  },
  {
    value: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    value: 'TV',
    name: 'Tuvalu',
  },
  {
    value: 'UG',
    name: 'Uganda',
  },
  {
    value: 'UA',
    name: 'Ukraine',
  },
  {
    value: 'AE',
    name: 'United Arab Emirates',
  },
  {
    value: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    value: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    value: 'US',
    name: 'United States of America',
  },
  {
    value: 'UY',
    name: 'Uruguay',
  },
  {
    value: 'UZ',
    name: 'Uzbekistan',
  },
  {
    value: 'VU',
    name: 'Vanuatu',
  },
  {
    value: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
  },
  {
    value: 'VN',
    name: 'Viet Nam',
  },
  {
    value: 'VG',
    name: 'Virgin Islands (British)',
  },
  {
    value: 'VI',
    name: 'Virgin Islands (U.S.)',
  },
  {
    value: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    value: 'EH',
    name: 'Western Sahara',
  },
  {
    value: 'YE',
    name: 'Yemen',
  },
  {
    value: 'ZM',
    name: 'Zambia',
  },
  {
    value: 'ZW',
    name: 'Zimbabwe',
  },
]

const US_STATE_CODES = [
  {
    value: 'AL',
    name: 'Alabama',
  },
  {
    value: 'AK',
    name: 'Alaska',
  },
  {
    value: 'AZ',
    name: 'Arizona',
  },
  {
    value: 'AR',
    name: 'Arkansas',
  },
  {
    value: 'CA',
    name: 'California',
  },
  {
    value: 'CO',
    name: 'Colorado',
  },
  {
    value: 'CT',
    name: 'Connecticut',
  },
  {
    value: 'DE',
    name: 'Delaware',
  },
  {
    value: 'DC',
    name: 'District Of Columbia',
  },
  {
    value: 'FL',
    name: 'Florida',
  },
  {
    value: 'GA',
    name: 'Georgia',
  },
  {
    value: 'HI',
    name: 'Hawaii',
  },
  {
    value: 'ID',
    name: 'Idaho',
  },
  {
    value: 'IL',
    name: 'Illinois',
  },
  {
    value: 'IN',
    name: 'Indiana',
  },
  {
    value: 'IA',
    name: 'Iowa',
  },
  {
    value: 'KS',
    name: 'Kansas',
  },
  {
    value: 'KY',
    name: 'Kentucky',
  },
  {
    value: 'LA',
    name: 'Louisiana',
  },
  {
    value: 'ME',
    name: 'Maine',
  },
  {
    value: 'MD',
    name: 'Maryland',
  },
  {
    value: 'MA',
    name: 'Massachusetts',
  },
  {
    value: 'MI',
    name: 'Michigan',
  },
  {
    value: 'MN',
    name: 'Minnesota',
  },
  {
    value: 'MS',
    name: 'Mississippi',
  },
  {
    value: 'MO',
    name: 'Missouri',
  },
  {
    value: 'MT',
    name: 'Montana',
  },
  {
    value: 'NE',
    name: 'Nebraska',
  },
  {
    value: 'NV',
    name: 'Nevada',
  },
  {
    value: 'NH',
    name: 'New Hampshire',
  },
  {
    value: 'NJ',
    name: 'New Jersey',
  },
  {
    value: 'NM',
    name: 'New Mexico',
  },
  {
    value: 'NY',
    name: 'New York',
  },
  {
    value: 'NC',
    name: 'North Carolina',
  },
  {
    value: 'ND',
    name: 'North Dakota',
  },
  {
    value: 'OH',
    name: 'Ohio',
  },
  {
    value: 'OK',
    name: 'Oklahoma',
  },
  {
    value: 'OR',
    name: 'Oregon',
  },
  {
    value: 'PA',
    name: 'Pennsylvania',
  },
  {
    value: 'RI',
    name: 'Rhode Island',
  },
  {
    value: 'SC',
    name: 'South Carolina',
  },
  {
    value: 'SD',
    name: 'South Dakota',
  },
  {
    value: 'TN',
    name: 'Tennessee',
  },
  {
    value: 'TX',
    name: 'Texas',
  },
  {
    value: 'UT',
    name: 'Utah',
  },
  {
    value: 'VT',
    name: 'Vermont',
  },
  {
    value: 'VA',
    name: 'Virginia',
  },
  {
    value: 'WA',
    name: 'Washington',
  },
  {
    value: 'WV',
    name: 'West Virginia',
  },
  {
    value: 'WI',
    name: 'Wisconsin',
  },
  {
    value: 'WY',
    name: 'Wyoming',
  },
]

const TEMPORARY_DISABLE_ADD_CARD = false

const USER_ACTIONS = {
  BID: 'bid',
  PURCHASE: 'purchase',
  ENTER: 'enter',
  PARTICIPATE: 'participate',
}

// setTimeout breaks over 2147483647 ms, which is about 24.8 days
// this value should be equal to or less than that
const MAX_SET_TIMEOUT_DELAY = 2147483647

const ETHEREUM_PROVIDER =
  process.env.NEXT_PUBLIC_REACT_APP_WEB3_CHAIN_ENVIRONMENT === 'production'
    ? 'https://eth-mainnet.alchemyapi.io/jsonrpc/ER1Uh6Lu38x2xWXc7IomSmYFO5twNigV'
    : 'https://eth-rinkeby.alchemyapi.io/jsonrpc/uoTExPhI5jIhtRGbVlKWHXftWYa-vqj6'

export {
  URL,
  LEGACY_URL,
  ENDPOINT,
  SEARCH_URL,
  BREAKPOINTS,
  GUIDES,
  DISPLAY_CURRENCY,
  MARKETPLACE_PAGE_SIZE,
  NIFTY_IMAGE_URL,
  APPLY_TO_CREATE_WITH_NIFTY,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  ITEM_HISTORY_SIZE,
  API_URL,
  ETHERSCAN,
  ZENDESK_SUPPORT_URL,
  NIFTY_HELP_URL,
  FAQ_URL,
  COUNTRY_CODES,
  TEMPORARY_DISABLE_ADD_CARD,
  USER_ACTIONS,
  POST_AUTH_REDIRECT_PARAM,
  MAX_SET_TIMEOUT_DELAY,
  RECUR_POINT_NAME,
  PAK_MERGE_ADDRESS,
  PAK_OFFER_WARNING,
  MASTER_PROFILE_URL,
  ETHEREUM_PROVIDER,
  ANALYTICS_EVENTS,
  US_STATE_CODES,
}

export const METAMASK_ICON_URL =
  'https://media.niftygateway.com/image/upload/v1646231999/AA/metamask-alternative_1_dvrill.svg'

export const ENABLE_IMAGE_PAN_AND_ZOOM_CONTRACTS = [
  '0x0216bd035a4620867d7adab9d0776c83dafb57ff',
  '0x057deaa86e69229f09193254f6e4985dcbe59d80',
]

export const MIXPANEL_EVENTS = {
  PAN_AND_ZOOM_ENABLED: 'Primary Nifty Viewed in Fullscreen',
}

export const SARDINE_UUID_KEY = 'uuid'

export const MintableNFTABI = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [
      {
        name: '_name',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'getApproved',
    outputs: [
      {
        name: '_approved',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'implementsERC721',
    outputs: [
      {
        name: '_implementsERC721',
        type: 'bool',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        name: '_totalSupply',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_from',
        type: 'address',
      },
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'transferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
      {
        name: '_index',
        type: 'uint256',
      },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'ownerOf',
    outputs: [
      {
        name: '_owner',
        type: 'address',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'tokenMetadata',
    outputs: [
      {
        name: '_infoUrl',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        name: '_balance',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
      {
        name: '_tokenId',
        type: 'uint256',
      },
      {
        name: '_approvedAddress',
        type: 'address',
      },
      {
        name: '_metadata',
        type: 'string',
      },
    ],
    name: 'mint',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        name: '_symbol',
        type: 'string',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      {
        name: '_to',
        type: 'address',
      },
      {
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'numTokensTotal',
    outputs: [
      {
        name: '',
        type: 'uint256',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      {
        name: '_owner',
        type: 'address',
      },
    ],
    name: 'getOwnerTokens',
    outputs: [
      {
        name: '_tokenIds',
        type: 'uint256[]',
      },
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: '_to',
        type: 'address',
      },
      {
        indexed: true,
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'Mint',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: '_from',
        type: 'address',
      },
      {
        indexed: true,
        name: '_to',
        type: 'address',
      },
      {
        indexed: false,
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: '_owner',
        type: 'address',
      },
      {
        indexed: true,
        name: '_approved',
        type: 'address',
      },
      {
        indexed: false,
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
] as const
