"use client";

import * as React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import EmotionCacheProvider from "./EmotionCacheProvider";
import { theme } from "../elements/src";

export default function MuiThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <EmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleReCaptchaProvider
          reCaptchaKey={
            process.env.NEXT_PUBLIC_REACT_APP_CAPTCHA_SITEKEY_V3 as string
          }
          scriptProps={{
            async: true,
            defer: true,
          }}
        >
          {children}
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </EmotionCacheProvider>
  );
}
