import { useQuery } from "@tanstack/react-query";
import {
  CollectorAppreciationParams,
  CollectorAppreciationResponse,
  ApiResponse,
} from "@/types/api_types";
import { AuthCredentials } from "@/types/global";
import {
  fetchWithHeader,
  CREDENTIALS_ERROR_MSG,
  createQueryString,
  createURL,
  getErrorPayload,
  getRequestNotOkMsg,
} from "@/utils/api";
import useAuth from "./useAuth";

const getCollectorAppreciationInfo = async (
  credentials: AuthCredentials,
  params?: CollectorAppreciationParams
): Promise<ApiResponse<CollectorAppreciationResponse>> => {
  if (!credentials.access_token) {
    throw getErrorPayload(new Error(CREDENTIALS_ERROR_MSG));
  }

  try {
    let url = createURL(`/user/collector-appreciation/`);
    if (params) {
      url = createURL(
        `/user/collector-appreciation/?${createQueryString(params)}`
      );
    }
    const response = await fetchWithHeader(url, {
      headers: { Authorization: `Bearer ${credentials.access_token}` },
    });

    if (!response.ok) {
      throw getRequestNotOkMsg(response.status);
    }

    const data = await response.json();

    return {
      data,
    };
  } catch (err) {
    throw getErrorPayload(err);
  }
};

const getQueryKey = (params?: CollectorAppreciationParams) =>
  !params
    ? ["CollectorAppreciation"]
    : [
        "CollectorAppreciation",
        params.market,
        params.contract_address,
        params.token_id,
      ];

export const useCollectorAppreciation = (
  params?: CollectorAppreciationParams
) => {
  const { isLoggedIn, values } = useAuth();
  const queryEnabled = isLoggedIn() && !!values.credentials?.access_token
  const query = useQuery({
    queryKey: getQueryKey(params),
    queryFn: async () => {
      const { data } = await getCollectorAppreciationInfo(
        values.credentials,
        params
      );

      return data!.message;
    },
    enabled: queryEnabled,
  });

  return {
    ...query,
    enabled: queryEnabled
  }
};
