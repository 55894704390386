import qs from 'qs'

const buildUrl = <T>(path: string, params: T): string => {
  const queryParams = qs.stringify(params, {
    skipNulls: true,
    encode: false,
    sort: (a, b) => a.localeCompare(b),
  })
  const query = queryParams.length > 0 ? `?${queryParams}` : ''

  return `${path}${query}`
}

export default buildUrl
