export const FORM_TEXT = {
  confirm: 'Confirm Offer',
  title: 'Make an offer for',
  subtitle:
    'A global offer will show to all holders of any edition of this NFT.',
  amountLabel: 'Offer Amount',
  breakdownTitle: 'Confirm Offer',
  byReplacing: `By placing a bid, you’re committing to buy this item if it’s accepted.
    Owners of this NFT will see that you made an offer for this NFT. If
    they accept, you will pay the agreed upon amount, and ownership of the
    NFT will be transferred to you. When you place an offer, an
    authorization for the amount you offered will be placed on your card.
    If the offer is accepted, the authorization is converted to a charge.
    If you cancel the offer, if the offer is declined, or if the offer
    expires (which will happen after 7 days), the authorization will be
    reversed and the charge will disappear from your card instantly. If
    this is your first NFT purchase and you are purchasing with a Credit
    Card, your account will receive a Nifty Hold for 72 hrs. Under Nifty
    Hold:`,
  cannotWithdraw:
    'You cannot withdraw or transfer NFTs from your account, but you can still purchase more NFTs.',
  sellOnMarketplace:
    'If you are an Authorized Seller, you can sell on the marketplace.',
  avoidHold:
    'You can avoid the Nifty Hold by making your first purchase with Prepaid Eth.',
}
