import { BasicResponse, ApiResponse } from "@/types/api_types";
import { 
  fetchWithHeader, 
  createURL, 
  getErrorPayload, 
  getRequestNotOkMsg 
} from "@/utils/api";

export interface ActiveStyleResponse extends BasicResponse {
  active_style?: string;
  logo?: string;
}

export const getActiveStyles = async (): Promise<
  ApiResponse<ActiveStyleResponse>
> => {
  try {
    const response = await fetchWithHeader(createURL("/customization/active-styles/"));

    if (!response.ok) {
      throw getRequestNotOkMsg(response.status);
    }

    const results = await response.json();

    return {
      data: results,
    };
  } catch (err: unknown) {
    throw getErrorPayload(err);
  }
};

export interface CTABannerInfo {
  main_text: string;
  main_text_color: string;
  main_background_color: string;
  button_text: string;
  button_text_color: string;
  button_background_color: string;
  button_link: string;
  priority: number;
  banner_name: string;
}

export const getCTABanner = async (): Promise<ApiResponse<CTABannerInfo[]>> => {
  try {
    const response = await fetchWithHeader(createURL("/home/cta/"));

    if (!response.ok) {
      throw getRequestNotOkMsg(response.status);
    }

    const results = await response.json();

    return {
      data: results,
    };
  } catch (err: unknown) {
    throw getErrorPayload(err);
  }
};
