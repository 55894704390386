// Undo MUI centering of labels for first and last labels so they don't grow beyon the container
import { Slider, styled } from '@mui/material'

const NiftySlider = styled(Slider)(() => ({
  '&.MuiSlider-root': {
    paddingTop: 65, // padding to account for the label above the slider
  },
  '.MuiSlider-thumb': {
    height: 14,
    width: 14,
    color: '#3f4041',
  },
  '.MuiSlider-rail': {
    backgroundColor: '#C8C8C8',
    opacity: 1,
  },
  '.MuiSlider-valueLabel': {
    backgroundColor: '#010304',
    borderRadius: 5,
    padding: '1px 6px 1px 6px',
  },
  '.MuiSlider-valueLabel::before': {
    backgroundColor: '#010304',
    width: 0,
    height: 0,
  },
  'span:nth-child(1 of .MuiSlider-markLabel)': {
    transform: ' translateX(0)',
    left: '0px !important',
  },
  'span:nth-last-child(1 of .MuiSlider-markLabel)': {
    transform: 'translateX(0)',
    left: 'inherit !important',
    right: '0px !important',
  },
}))

export default NiftySlider
