// Set of all errorType values (from API errors) that we know how to recover from
// If we encounter any of these errors, we don't need to close the modal or redirect away from the page
export const RECOVERABLE_ERROR_TYPES = [
  // User can simply try again
  'invalid_2fa_result',
  // Trying to submit payment again will result in a new captcha token being generated
  'invalid_captcha',
  // Backend provides the id of the existing, in-progress purchase intent on the response,
  // which the PaymentForm error handler will use to update the URL param which will allow user to proceed
  'active_purchase_already_in_progress',
  // 'expired' quotes can simply be regenerated on the backend, which the PaymentForm error handler takes care of for us
  'expired',
  // User needs to update their bid value and try again
  'AUCTION_BID_INVALID',
]
