import { DetectorOptions } from './types'

export default {
  detect(options: DetectorOptions) {
    const found = []

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i += 1) {
          found.push(navigator.languages[i])
        }
      }
      // @ts-ignore
      if (window.navigator.userLanguage) {
        // @ts-ignore
        found.push(window.navigator.userLanguage)
      }
      if (navigator.language) {
        found.push(navigator.language)
      }
    }

    return found[0]
  },
}
