import React from 'react'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import RankingSimulator from '@/elements/src/RankingSimulator'
import NiftyAccordion from '@/elements/src/NiftyAccordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { BidInfo } from '@/types/api_types'
import { RANKING_SIMULATOR_TITLE } from './constants'

interface RankingSimulatorContainerProps {
  sortedBids: BidInfo[]
  lowestDisplayRank: number
}

const RankingSimulatorContainer = ({
  sortedBids,
  lowestDisplayRank,
}: RankingSimulatorContainerProps) => {
  return (
    <NiftyAccordion
      sx={{
        backgroundColor: '#F8F8F8',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='ranking-simulator-content'
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            columnGap: 8,
          }}
        >
          <TrendingUpIcon />
          <span style={{ fontWeight: 600, fontSize: 14 }}>
            {RANKING_SIMULATOR_TITLE}
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <RankingSimulator
          sortedBids={sortedBids}
          lowestDisplayRank={lowestDisplayRank}
        />
      </AccordionDetails>
    </NiftyAccordion>
  )
}

export default RankingSimulatorContainer
