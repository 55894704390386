import { useMemo } from 'react'
import type { BidInfo } from '@/types/api_types'
import { Currency } from '@/types/payment'
import useExchangeRate from '@/hooks/useExchangeRate'
import { numberWithSuffix } from '@/elements/src/utils'
import { useUserPreferenceContext } from '@/providers/UserPreferenceContext'

/** Just a convenience type containing the BidInfo-derived data used by the component */
type SimulatedBid = {
  /** The bid amount, possibly converted into a Currency */
  amount: number
  /** The bid's rank formatted like "1st", "2nd", etc. */
  rankLabel: string
  /** The label displayed above the tick by MUI Slider */
  label: string
  /** The value associated with the tick by MUI Slider */
  value: number
}

/**
 * Transforms `BidInfo[]` into `SimulatedBid[]`,
 * limits the number of bids to `maxDisplayRank`,
 * and attempts to convert the amounts into the user's display currency.
 * Defaults to USD if no exchange rate is available for the user's display currency.
 * @param sortedBids An array of `BidInfo` sorted by highest amount first
 * @returns The sorted bid amounts in the display currency
 */
export default function useSimulatedBids(
  sortedBids: Pick<BidInfo, 'amount'>[],
  lowestDisplayRank: number,
) {
  const {
    userPreferences: { displayCurrency: userDisplayCurrencyPreference },
  } = useUserPreferenceContext()

  const { data: exchangeRateData } = useExchangeRate([
    userDisplayCurrencyPreference,
    Currency.USD,
  ])
  /** If no exchange rate is available, display amounts in USD */
  const displayCurrency = exchangeRateData
    ? userDisplayCurrencyPreference
    : Currency.USD

  const sortedSimulatedBids: SimulatedBid[] = useMemo(
    () =>
      (sortedBids.length ? sortedBids : [{ amount: 0 }])
        .filter((_, idx) => idx < Math.max(1, lowestDisplayRank))
        .map((bid, idx) => {
          const { amount: amountInDollars = 0 } = bid
          const amount = exchangeRateData
            ? amountInDollars / exchangeRateData
            : amountInDollars
          // getters for a bit of #defensiveprogramming
          return {
            get amount() {
              return amount
            },
            get rankLabel() {
              return numberWithSuffix(idx + 1)
            },
            get label() {
              return `${amount.toFixed(2)} ${displayCurrency}`
            },
            get value() {
              return amount
            },
          }
        }),
    [sortedBids, exchangeRateData, displayCurrency],
  )

  return { sortedSimulatedBids, displayCurrency }
}
