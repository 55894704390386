import { useQuery } from '@tanstack/react-query'

import { fetchApi } from '@/utils/api'

import useAuth from './useAuth'
import { GetPublishersResponse } from '@/types/api_types'

async function getUserPublisher(): Promise<GetPublishersResponse> {
  return fetchApi({
    path: '/publisher',
  })
}

export default function usePublisherPermissions() {
  const { isLoggedIn } = useAuth()
  const queryEnabled = isLoggedIn()

  const pubQuery = useQuery(
    ['publisherPermissions'],
    async () => {
      const data = await getUserPublisher()

      return data.results
    },
    {
      enabled: queryEnabled,
      refetchOnMount: false,
      retry: false,
    },
  )

  return {
    ...pubQuery,
    isLoading: queryEnabled ? pubQuery.isLoading : pubQuery.isInitialLoading,
  }
}
