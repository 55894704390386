import {
  type AuctionBid,
  type AuctionBidHistoryResponse,
  type MaxBidResponse,
  type SilentAuctionDetailsResponse,
} from '@/types/api_types'
import { type Currency } from '@/types/payment'
import { fetchApi } from '@/utils/api'

export const getAuctionMaxBid = (
  contractAddress: string,
  niftyType: string | number,
): Promise<MaxBidResponse> =>
  fetchApi({
    path: '/user/get-pm-auction-max-bid/',
    method: 'POST',
    body: JSON.stringify({ contractAddress, niftyType }),
  })

export const auctionBidHistory = (
  bidData: AuctionBid,
): Promise<AuctionBidHistoryResponse> =>
  fetchApi({
    path: '/market/pm-auction-all-bids/',
    queryParams: { ...bidData },
  })

export const getAuctionMinimumViableBid = (
  contractAddress: string,
  niftyType: string | number,
): Promise<{ min_bid_amount: number; min_bid_currency: Currency }> =>
  fetchApi({
    path: '/user/get-pm-auction-min-viable-bid/',
    queryParams: { contractAddress, niftyType },
  })

export const getSilentAuctionDetails = (
  contractAddress: string,
  niftyType: string | number,
): Promise<SilentAuctionDetailsResponse> =>
  fetchApi({
    path: '/user/get-pm-silent-auction-details/',
    method: 'POST',
    body: JSON.stringify({ contractAddress, niftyType }),
  })
