import { useMemo } from 'react'
import { Text } from '@hubble/web'
import { Box } from '@mui/material'
import {
  Money,
  Timeline,
  TimelineItem,
  TimelineStepStatus,
} from '@/elements/src'

import { Currency } from '@/types/payment'
import { PurchaseState } from '@/types/global'

import {
  OFFER_DELIVERY_STEP_DESCRIPTIONS,
  OFFER_DELIVERY_STEP_NAMES,
  OFFER_PENDING_STEP_DESCRIPTIONS,
  OFFER_PENDING_STEP_NAMES,
  OFFER_STATUS_DESCRIPTIONS,
  OFFER_STATUS_TITLES,
} from './constants'

interface OfferStatusProps {
  amount: string | number
  currency: Currency
  state: PurchaseState
}

export default function OfferStatus({
  amount,
  currency,
  state,
}: OfferStatusProps) {
  const offerPendingStepStatus = ((): TimelineStepStatus => {
    switch (state) {
      case 'CANCELED':
      case 'AUTHORIZE_FAILED':
        return 'failed'

      case 'COMPLETED':
        return 'success'

      default:
        return 'in_progress'
    }
  })()

  const offerPendingStepName = (() => {
    switch (state) {
      case 'AUTHORIZE_FAILED':
        return OFFER_PENDING_STEP_NAMES.PAYMENT_FAILED

      case 'CANCELED':
        return OFFER_PENDING_STEP_NAMES.DECLINED

      case 'COMPLETED':
        return OFFER_PENDING_STEP_NAMES.ACCEPTED

      default:
        return OFFER_PENDING_STEP_NAMES.PENDING
    }
  })()

  const offerPendingStepDescription = (() => {
    switch (state) {
      case 'AUTHORIZE_FAILED':
        return OFFER_PENDING_STEP_DESCRIPTIONS.PAYMENT_FAILED

      case 'CANCELED':
        return OFFER_PENDING_STEP_DESCRIPTIONS.CANCELED

      case 'COMPLETED':
        return (
          <span>
            <span>{OFFER_PENDING_STEP_DESCRIPTIONS.BEEN_CHARGED}</span>
            <Money
              value={[amount, currency]}
              displayAs={currency}
              convertFromCents={false}
            />
            <span>
              {OFFER_PENDING_STEP_DESCRIPTIONS.ON_SELECTED_PAYMENT_METHOD}
            </span>
          </span>
        )

      default:
        return OFFER_PENDING_STEP_DESCRIPTIONS.DEFAULT
    }
  })()

  const offerDeliveryStepDescription = (() => {
    switch (state) {
      case 'COMPLETED':
        return OFFER_DELIVERY_STEP_DESCRIPTIONS.NFT_SENT

      default:
        return OFFER_DELIVERY_STEP_DESCRIPTIONS.DEFAULT
    }
  })()

  const offerStatusTitle = (() => {
    switch (state) {
      case 'AUTHORIZE_FAILED':
        return OFFER_STATUS_TITLES.TRY_AGAIN

      case 'CANCELED':
        return OFFER_STATUS_TITLES.MAYBE_NEXT_TIME

      case 'COMPLETED':
        return OFFER_STATUS_TITLES.OFFER_ACCEPTED

      default:
        return OFFER_STATUS_TITLES.OFFER_SENT
    }
  })()

  const offerStatusDescription = (() => {
    switch (state) {
      case 'AUTHORIZE_FAILED':
        return OFFER_STATUS_DESCRIPTIONS.SOMETHING_WENT_WRONG

      case 'CANCELED':
        return OFFER_STATUS_DESCRIPTIONS.NOT_ACCEPTED

      case 'COMPLETED':
        return null

      default:
        return OFFER_STATUS_DESCRIPTIONS.DEFAULT
    }
  })()

  const steps = useMemo<TimelineItem[]>(() => {
    return [
      {
        name: offerPendingStepName,
        description: offerPendingStepDescription,
        isCurrentStep: state !== 'COMPLETED',
        status: offerPendingStepStatus,
      },
      {
        name: OFFER_DELIVERY_STEP_NAMES.DEFAULT,
        description: offerDeliveryStepDescription,
        isCurrentStep: state === 'COMPLETED',
        status: state === 'COMPLETED' ? 'success' : 'in_progress',
      },
    ]
  }, [amount, currency, state])

  return (
    <Box>
      <Text
        bold
        align='left'
        fontSize='32px'
        style={{
          marginBottom: 24,
        }}
      >
        {offerStatusTitle}
      </Text>
      <Text align='left'>{offerStatusDescription}</Text>
      <Timeline data-testid='offer-intent-timeline' steps={steps} />
    </Box>
  )
}
