import { useQuery } from "@tanstack/react-query";
import { PromotedSearchResultResponse, ApiResponse } from "@/types/api_types";
import {
  fetchWithHeader,
  createURL,
  getErrorPayload,
  getRequestNotOkMsg,
} from "@/utils/api";

const mapResult = (data: PromotedSearchResultResponse | null) => {
  return {
    storeEndTime: data?.scheduled_end_date_time || data?.nbco.scheduled_end_date_time,
    storeStartTime: data?.scheduled_start_date_time || data?.nbco.scheduled_start_date_time,
    collectionSlug: data?.collection_page_url,
    imageUrl: data?.image_url,
    collectionName: data?.nbco.storeName,
    creators: data?.nbco.creators || [],
  };
};

const getPromotedSearchResult = async (): Promise<
  ApiResponse<PromotedSearchResultResponse>
> => {
  try {
    // TODO: verify if being used
    const response = await fetchWithHeader(createURL(`/promoted-search`));

    if (!response.ok) {
      throw getRequestNotOkMsg(response.status);
    }

    const data = await response.json();

    return {
      data,
    };
  } catch (err) {
    throw getErrorPayload(err);
  }
};

export const usePromotedSearch = () => {
  const query = useQuery(
    ["PromotedSearchResult"],
    async () => {
      const { data } = await getPromotedSearchResult();

      return { data: mapResult(data) };
    },
    { retry: 0 }
  );

  return query;
};
