import isEqual from 'lodash/isEqual'

import { TraitOption } from '@/types/global'
import {
  PaymentCurrency,
  PaymentMethod,
  PurchaseIntent,
  StripePaymentIntent,
} from '@/types/payment-types'

export type OfferFormData = {
  paymentMethod: PaymentMethod
  paymentCurrency: PaymentCurrency
  offerAmount: string
  offerCurrency: PaymentCurrency
  traits: TraitOption[]
}

type ComplementObject = {
  stripePaymentIntent?: Nullable<StripePaymentIntent>
  prevTraits?: TraitOption[]
}

export function shouldUpdatePurchaseIntent(
  purchaseIntent: PurchaseIntent,
  formData: OfferFormData,
  { stripePaymentIntent, prevTraits }: ComplementObject = {},
) {
  if (purchaseIntent.state !== 'INITIATED') return false

  return (
    // the payment method has changed
    formData.paymentMethod !== purchaseIntent?.quotes[0].paymentMethod ||
    // OR the payment currency has changed
    formData.paymentCurrency !== purchaseIntent?.quotes[0].paymentCurrency ||
    // OR the offer amount has changed
    parseFloat(formData.offerAmount || '0') !==
      parseFloat(purchaseIntent?.quotes[0].paymentAmount || '0') ||
    // OR paymentMethod is a Stripe payment method, AND we don't have a Stripe PI
    ([PaymentMethod.BNPL, PaymentMethod.CARD].includes(
      formData.paymentMethod,
    ) &&
      !stripePaymentIntent) ||
    // OR traits has changed
    !isEqual(formData.traits, prevTraits)
  )
}

/**
 * Check whether a given formData is a valid offer purchase intent object
 */
export function validateOfferFormData(formData: OfferFormData) {
  // Object should have valid values
  if (!Object.values(formData).every((value) => !!value)) return false

  if (parseFloat(formData.offerAmount) === 0) return false

  return true
}
