import Image from "next/image";

export const Locales = {
  en: {
    label: "English",
    flag: <Image src="flags/us.svg" alt="English" width={21} height={16} />,
  },
  "es-LA": {
    label: "Español (Latino America)",
    flag: <Image src="flags/mx.svg" alt="Español" width={21} height={16} />,
  },
  "pt-BR": {
    label: "Português (Brazil)",
    flag: <Image src="flags/br.svg" alt="Português" width={21} height={16} />,
  },
};

export const loadLocaleData = async (locale: string) => {
  switch (locale) {
    case "es-LA":
      return import("./json/es-LA.json");

    case "pt-BR":
      return import("./json/pt-BR.json");

    default:
      return import("./json/en.json");
  }
};

export default {
  loadLocaleData,
};
