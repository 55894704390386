import { useState } from 'react'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import buildUrl from '@/utils/build-url'

const useNavigator = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const router = useRouter()

  const fullPath = buildUrl(pathname, searchParams.toString())
  const [prevRoute, setPrevRoute] = useState(fullPath)

  const navigateTo = (path: string, params: {}, scrollRestoration = true) => {
    const url: string = buildUrl<{}>(path, params)
    setPrevRoute(fullPath)
    router.push(url, { scroll: scrollRestoration })
    // https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#disabling-scroll-restoration
  }

  return { navigateTo, prevRoute }
}

export default useNavigator
