import React from 'react'

export const LocaleContext = React.createContext({
  currentLocale: 'en',
  changeLanguage(locale: string) {},
})

export function useLanguageContext() {
  const context = React.useContext(LocaleContext)
  if (context === undefined) {
    throw new Error('useLanguageContext must be used within a LocaleProvider')
  }
  return context
}
