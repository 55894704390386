import React from "react";
import { Box, styled } from "@mui/material";

import { PromotedCard } from "@/elements/src";

import useNavigator from "@/hooks/useNavigator";
import { useAnalytics } from "@/providers/AnalyticsContext";
import { usePromotedSearch } from "@/hooks/usePromotedSearch";

import SearchRecents from "./search-recents";
import SearchCategories from "./search-categories";
import { getRecentSearchCount } from "./search-storage";

const StyledBox = styled(Box)`
display: flex;
    overflow-y: auto;
    padding: 16px 6px;
    flex-direction: row;

    @media (min-width: 960px) {
      height: fit-content;
    }

    @media (max-width: 960px) {
      flex-direction: column;
    }
    
    .searchsuggests--boxContainer {
      width: 50%;
      margin-bottom: 16px;
      @media (max-width: 960px) {
        width: 100%;
        margin-bottom: 24px;
      }
    }
`;

interface SearchSuggestsProps {
  query: string | null;
  closeSearch: () => void;
}

const SearchSuggests = ({ query, closeSearch }: SearchSuggestsProps) => {
  const { logEvent } = useAnalytics();
  const { navigateTo } = useNavigator();

  const { data } = usePromotedSearch();

  const collection = data?.data;

  const recentSearches = getRecentSearchCount();

  if (query) return null;
  return (
    <StyledBox>
      <Box className="searchsuggests--boxContainer">
        {recentSearches > 0 ? (
          <SearchRecents
            onClickResult={({ itemPath, itemType, itemName }) => {
              logEvent?.("Recent Search Result Click", "Search", {
                searchPath: itemPath || "",
                searchType: itemType || "",
                searchName: itemName || "",
              });
              navigateTo(itemPath, {});
              closeSearch();
            }}
          />
        ) : (
          <SearchCategories
            onClickCategory={(category) => {
              logEvent?.("Search - Categories clicked", "Search", {
                filterName: category.name,
              });
              navigateTo("/marketplace", { ...category.filter });
              closeSearch();
            }}
          />
        )}
      </Box>
      <Box className="searchsuggests--boxContainer" padding="0 12px">
        <PromotedCard
          // @ts-ignore
          collection={collection}
          onClick={() => {
            logEvent?.("Promoted Search Result Click", "Search", {
              currentURL: window.location.pathname,
              collectionName: collection?.collectionName,
            });
            navigateTo(`/collections/${collection?.collectionSlug}`, {});
            closeSearch();
          }}
        />
      </Box>
    </StyledBox>
  );
};

export default SearchSuggests;
