'use client'

import React, { useCallback, useState } from "react";
import { useRouter } from "next/navigation";
import useAuth from "@/hooks/useAuth";
import AuthModal from "@/elements/src/AuthModal";
import AuthModalContext from "./AuthModalContext";

const AuthModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [showLogin, setShowLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [shouldRedirectOnClose, setShouldRedirectOnClose] = useState(false);
  const [onSuccessHandler, setOnSuccessHandler] = useState<() => void>();
  const { isLoggedIn } = useAuth();
  const history = useRouter();

  const handleOpenState = () => {
    if (isLoggedIn()) {
      setShowLogin(false);
    } else {
      setShowLogin(!showLogin);
    }
  };

  const toggleOpenAuthModal = (
    onSuccess?: () => void,
    redirectOnClose: boolean = false
  ) => {
    if (onSuccess) {
      // Using useState with a function type requires you to provide a higher-order function due to the internals of useState
      // see https://stackoverflow.com/a/55621679 for additional details
      setOnSuccessHandler(() => () => {
        // close modal
        setShowLogin(false);
        onSuccess();
      });
    }
    setShouldRedirectOnClose(redirectOnClose);
    handleOpenState();
  };

  const onClose = useCallback(() => {
    setShowLogin(false);
    if (!isLoggedIn() && shouldRedirectOnClose) {
      history.push("/");
    }
  }, [isLoggedIn, history, shouldRedirectOnClose]);

  return (
    <AuthModalContext.Provider
      value={{
        toggleOpenAuthModal,
        isOpen: showLogin,
        isSignup,
        toggleShowSignup: () => setIsSignup(!isSignup),
      }}
    >
      {showLogin && (
        <AuthModal
          isOpen={showLogin}
          isSignup={isSignup}
          onClose={onClose}
          onSuccess={onSuccessHandler}
        />
      )}
      {children}
    </AuthModalContext.Provider>
  );
};

export default AuthModalProvider;
