import { Modal } from '@hubble/web'
import { styled } from '@mui/material'

export const StyledModal = styled(Modal)`
  & > div {
    padding: 32px;
    max-width: 405px;
    min-width: 405px;

    & > div:first-child {
      position: absolute;
      right: 32px;
      top: 32px;
    }

    .ModalHeader {
      padding: 0;
    }
  }
`
