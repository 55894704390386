const doesPathMatch = (pathname: string, patterns: string[]): boolean => {
  return patterns.some((pattern) => {
    const regexPattern = pattern.replace(/\/\*/g, '/[^/]*').replace(/\*/g, '.*')

    const regex = new RegExp(`^${regexPattern}$`)
    return regex.test(pathname)
  })
}

export default doesPathMatch
