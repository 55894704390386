'use client'

import Script from 'next/script'
import { usePathname } from 'next/navigation'
import { useRef, useEffect } from 'react'
import { pathNamesToHideZenDesk } from '@/settings'
import doesPathMatch from '@/utils/does-path-match'

const INTERVAL_DELAY = 250

const getLauncherElement = () => {
  const zenDeskLauncher = document.getElementById('launcher')?.parentNode
    ?.parentNode as HTMLElement

  return zenDeskLauncher
}

// Loading strategy is set to afterInteractive: Load early but after some hydration on the page occurs.
const Zendesk = () => {
  const interval = useRef<NodeJS.Timeout>()
  const currentDisplay = useRef('')
  const pathname = usePathname()

  const handler = (zenDeskLauncher: HTMLElement) => {
    if (!currentDisplay.current)
      currentDisplay.current = zenDeskLauncher.style.display

    zenDeskLauncher.style.display = doesPathMatch(
      pathname,
      pathNamesToHideZenDesk,
    )
      ? 'none'
      : currentDisplay.current
  }

  useEffect(() => {
    const zenDeskLauncher = getLauncherElement()

    if (zenDeskLauncher) {
      handler(zenDeskLauncher)
    } else {
      interval.current = setInterval(() => {
        const zenDeskLauncher = getLauncherElement()
        if (zenDeskLauncher) {
          clearInterval(interval.current)
          handler(zenDeskLauncher)
        }
      }, INTERVAL_DELAY)
    }

    return () => {
      clearInterval(interval.current)

      const zenDeskLauncher = getLauncherElement()

      if (zenDeskLauncher)
        zenDeskLauncher.style.display = currentDisplay.current
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Script
      id='ze-snippet'
      src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_REACT_APP_ZENDESK_SNIPPET_KEY}`}
      strategy='afterInteractive'
    />
  )
}

export default Zendesk
