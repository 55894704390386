import { useMemo, useState } from 'react'
import { useDebounce } from 'react-use'

import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { colors } from '@/elements/src'

import { usePaymentOptions } from '@/hooks/usePaymentOptions'
import useDefaultPaymentMethod from '@/hooks/useDefaultPaymentMethod'
import useDialog from '@/hooks/useDialog'

import {
  PaymentCurrency,
  PurchaseIntent,
  StripePaymentIntent,
  getDefaultPaymentCurrency,
} from '@/types/payment-types'
import { UpdateOfferPurchaseIntentPayload } from '@/types/api_types'
import { TraitOption } from '@/types/global'

import {
  OfferFormData,
  shouldUpdatePurchaseIntent,
  validateOfferFormData,
} from '../utils'
import { getQuoteBreakdownItems } from '../../checkout/[contractAddress]/[niftyType]/lineItems'
import { TraitGroup } from '../../(legacy-checkout)/makeglobaloffer/types'
import { FORM_TEXT } from '../constants'
import { GLOBAL_OFFER_PAGE_CONSTANTS } from '../../(legacy-checkout)/makeglobaloffer/constants'

import DollarNumberFormat from '../../(legacy-checkout)/_components/DollarNumberFormat'
import EtherNumberFormat from '../../listing/_components/EtherNumberFormat'
import PaymentSelect from '../../checkout/_components/PaymentSelect'
import TermsAndConditionsCheckbox from '../../(legacy-checkout)/_components/TermsAndConditionsCheckbox'
import PaymentForm from '../../checkout/_components/PaymentForm'

interface OfferFormProps {
  itemTitle: string
  purchaseIntent?: PurchaseIntent
  stripePaymentIntent: Nullable<StripePaymentIntent>
  isLoading: boolean
  isUpdating: boolean
  traitGroups?: TraitGroup[]
  isCollectingTax?: boolean
  onUpdatePurchaseIntent(updateData: UpdateOfferPurchaseIntentPayload): void
  onSuccess(formData: OfferFormData): void
}

export default function OfferForm({
  itemTitle,
  purchaseIntent,
  stripePaymentIntent,
  isLoading,
  isUpdating,
  traitGroups,
  isCollectingTax = false,
  onUpdatePurchaseIntent,
  onSuccess,
}: OfferFormProps) {
  const { openDialog, RenderDialog } = useDialog()
  const [isTermAndConditionsAccepted, setIsTermAndConditionsAccepted] =
    useState(false)
  const [amount, setAmount] = useState(
    purchaseIntent?.quotes[0].paymentAmount ?? '0',
  )

  const { defaultPaymentMethod } = useDefaultPaymentMethod()

  const [offerFormData, setOfferFormData] = useState<OfferFormData>({
    offerAmount: amount,
    offerCurrency:
      purchaseIntent?.quotes[0].paymentCurrency ??
      getDefaultPaymentCurrency(defaultPaymentMethod),
    paymentMethod:
      purchaseIntent?.quotes[0].paymentMethod ?? defaultPaymentMethod,
    paymentCurrency:
      purchaseIntent?.quotes[0].paymentCurrency ??
      getDefaultPaymentCurrency(defaultPaymentMethod),
    traits: [],
  })

  const { enabledPaymentMethods = [] } = usePaymentOptions(
    purchaseIntent?.purchaseIntentId,
  )
  const isValidFormData = validateOfferFormData(offerFormData)

  const breakdownItems = useMemo(() => {
    return getQuoteBreakdownItems(isCollectingTax, 'Offer')
  }, [isCollectingTax])

  const handlePurchaseDetailUpdate = (data: Partial<OfferFormData>) => {
    const newData = { ...offerFormData, ...data }

    if (!purchaseIntent) return
    if (isUpdating || isLoading) return

    const isNewDetails = shouldUpdatePurchaseIntent(purchaseIntent, newData, {
      stripePaymentIntent,
    })
    const isValidData = validateOfferFormData(newData)

    if (isNewDetails && isValidData) {
      onUpdatePurchaseIntent({
        purchase_intent_id: purchaseIntent.purchaseIntentId,
        payment_method: newData.paymentMethod,
        payment_currency: newData.paymentCurrency,
        offer_currency: newData.paymentCurrency,
        offer_amount: newData.offerAmount,
        traits: newData.traits.length > 0 ? newData.traits : undefined,
      })
    }

    setOfferFormData(newData)
  }
  const handleRegenerateQuote = () => {
    if (!purchaseIntent) return
    if (isUpdating || isLoading) return

    onUpdatePurchaseIntent({
      purchase_intent_id: purchaseIntent.purchaseIntentId,
      payment_method: offerFormData.paymentMethod,
      payment_currency: offerFormData.paymentCurrency,
      offer_currency: offerFormData.paymentCurrency,
      offer_amount: offerFormData.offerAmount,
      traits:
        offerFormData.traits.length > 0 ? offerFormData.traits : undefined,
    })
  }

  const handleTraitSelect = (trait: TraitOption) => {
    const prevTraits = offerFormData.traits

    if (prevTraits.find((t) => t.name === trait.name)) {
      const newTraits = prevTraits
        .map((t) => {
          if (t.name === trait.name) {
            return { ...t, value: trait.value }
          }
          return t
        })
        .filter((t) => t.value !== '')

      handlePurchaseDetailUpdate({
        traits: newTraits,
      })
    } else {
      handlePurchaseDetailUpdate({
        traits: [...prevTraits, trait],
      })
    }
  }

  useDebounce(
    () => {
      handlePurchaseDetailUpdate({ offerAmount: amount })
    },
    500,
    [amount],
  )

  return (
    <Stack direction='column'>
      <Typography variant='h2' sx={{ fontSize: 24, fontWeight: 600 }}>
        {FORM_TEXT.title} {itemTitle}
      </Typography>

      <Typography sx={{ fontSize: 12, fontWeight: 400, mb: 3 }}>
        {FORM_TEXT.subtitle}
      </Typography>

      <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
        {FORM_TEXT.amountLabel}
      </Typography>
      <TextField
        autoFocus
        required
        fullWidth
        sx={{
          mt: 2,
          mb: 3,
        }}
        id='amount'
        name='amount'
        margin='normal'
        variant='outlined'
        onChange={(e) => setAmount(e.target.value)}
        value={amount}
        inputProps={{
          'data-testid': 'confirm-bid-input',
        }}
        InputProps={{
          'aria-label': 'Bid amount',
          inputComponent:
            offerFormData.paymentCurrency === PaymentCurrency.USD
              ? DollarNumberFormat
              : EtherNumberFormat,
        }}
      />

      {Array.isArray(traitGroups) && traitGroups.length > 0 && (
        <Stack direction='column' gap={2} sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
            {GLOBAL_OFFER_PAGE_CONSTANTS.TRAITS_HEADING}
          </Typography>

          {traitGroups?.map((traitGroup: TraitGroup) => {
            return (
              <Autocomplete
                key={traitGroup.name}
                options={traitGroup.values}
                getOptionLabel={(option) => option.value}
                filterSelectedOptions
                onChange={(_, value) => {
                  if (value === null) {
                    handleTraitSelect({
                      name: traitGroup.name,
                      value: '',
                    })
                  } else {
                    handleTraitSelect(value as TraitOption)
                  }
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant='outlined'
                    label={traitGroup.name}
                    placeholder={traitGroup.name}
                  />
                )}
              />
            )
          })}
        </Stack>
      )}

      <PaymentSelect
        labelStyle={{
          fontSize: 18,
          fontWeight: 600,
        }}
        purchaseIntent={purchaseIntent}
        paymentMethod={offerFormData.paymentMethod}
        setPaymentMethod={(pm, pc) =>
          handlePurchaseDetailUpdate({ paymentMethod: pm, paymentCurrency: pc })
        }
        paymentCurrency={offerFormData.paymentCurrency}
        enabledPaymentMethods={enabledPaymentMethods}
      />
      <PaymentForm
        afterSubmit={() => onSuccess(offerFormData)}
        canSubmit={
          isTermAndConditionsAccepted &&
          !isUpdating &&
          !isLoading &&
          isValidFormData
        }
        invoiceSectionTitle={FORM_TEXT.breakdownTitle}
        lineItems={breakdownItems}
        openDialog={openDialog}
        paymentCurrency={offerFormData.paymentCurrency}
        paymentMethod={offerFormData.paymentMethod}
        purchaseIntentId={purchaseIntent?.purchaseIntentId}
        regenerateQuote={handleRegenerateQuote}
        setPaymentCurrency={(pc) =>
          handlePurchaseDetailUpdate({ paymentCurrency: pc })
        }
        submitButtonText={FORM_TEXT.confirm}
        usePaymentInfo={() => ({
          purchaseIntent,
          isLoading,
          stripePaymentIntent,
        })}
        bottomAnchorChildren={
          <>
            <section>
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: colors.grayText2,
                  '> ul': { pl: 1 },
                }}
              >
                {FORM_TEXT.byReplacing}
                <ul>
                  <li>{FORM_TEXT.cannotWithdraw}</li>
                  <li>{FORM_TEXT.sellOnMarketplace}</li>
                </ul>
                {FORM_TEXT.avoidHold}
              </Typography>
            </section>

            <section>
              <TermsAndConditionsCheckbox
                isChecked={isTermAndConditionsAccepted}
                onChange={(value) => setIsTermAndConditionsAccepted(value)}
              />
            </section>
          </>
        }
      />
      <RenderDialog hideCancelButton />
    </Stack>
  )
}
