import React from 'react'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import NiftyAccordion from '@/elements/src/NiftyAccordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SuggestedBids from '../SuggestedBids/SuggestedBids'
import { Currency } from '@/types/payment'
import { IconRewardOutlined } from '@hubble/icons'

interface SuggestedBidsContainerProps {
  bidIncrement: number
  bidCurrency: Currency
  firstPlaceBid: number
  leaderboardMinBid: number
}

const SuggestedBidsContainer = ({
  bidIncrement,
  bidCurrency,
  firstPlaceBid,
  leaderboardMinBid,
}: SuggestedBidsContainerProps) => {
  return (
    <NiftyAccordion
      sx={{
        backgroundColor: '#F8F8F8',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='suggested-bids-content'
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            columnGap: 8,
          }}
        >
          <IconRewardOutlined />
          <span style={{ fontWeight: 600, fontSize: 14 }}>Suggested bids</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <SuggestedBids
          bidIncrement={bidIncrement}
          bidCurrency={bidCurrency}
          firstPlaceBid={firstPlaceBid}
          leaderboardMinBid={leaderboardMinBid}
        />
      </AccordionDetails>
    </NiftyAccordion>
  )
}

export default SuggestedBidsContainer
