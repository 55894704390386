import { useQuery } from '@tanstack/react-query'
import {
  auctionBidHistory,
  getAuctionMaxBid,
  getAuctionMinimumViableBid,
} from '../_api/auction'

type AuctionParams = {
  contractAddress: string
  niftyType: string
  enabled?: boolean
}
export const useAuction = ({
  contractAddress,
  niftyType,
  enabled = true,
}: AuctionParams) => {
  return useQuery({
    enabled,
    queryKey: ['pm-auction-max-bid', contractAddress, niftyType],
    queryFn: () =>
      getAuctionMaxBid(contractAddress, niftyType).then((res) => res.data),
    retry: false,
  })
}

type Params = {
  contractAddress: Nullable<string>
  niftyType: Nullable<string | number>
  size: number
  current: number
  enabled?: boolean
  refetchInterval?: number | false
}
export const useBidHistory = ({
  contractAddress,
  niftyType,
  current,
  size,
  enabled = true,
  refetchInterval = 60_000,
}: Params) => {
  const { data, ...rest } = useQuery({
    queryKey: ['bidHistory', contractAddress, niftyType, current, size],
    queryFn: async () => {
      const { data } = await auctionBidHistory({
        // Can get away with non-null assertion here since the query is only enabled if properties are present
        contractAddress: contractAddress!,
        niftyType: niftyType!,
        current,
        size,
      })
      return {
        results: data.results,
        totalBids: data.meta.page.total_results,
        mostRecentBids: data.results
          .slice()
          .sort(
            (a, b) =>
              new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime(),
          )
          ?.slice(-5),
      }
    },
    enabled: enabled && !!contractAddress && !!niftyType,
    refetchInterval,
    staleTime: 60_000,
  })

  return {
    bidHistory: data?.results,
    totalBids: data?.totalBids ?? 0,
    mostRecentBids: data?.mostRecentBids,
    ...rest,
  }
}

export const useAuctionMinimumViableBid = (
  contractAddress: string,
  niftyType: string | number,
) => {
  const { data, ...rest } = useQuery({
    queryKey: ['auctionMinBid', contractAddress, niftyType],
    queryFn: () => getAuctionMinimumViableBid(contractAddress, niftyType),
  })

  return {
    auctionMinimumViableBidData: data,
    ...rest,
  }
}
