import { format } from 'date-fns'
import { Typography } from '@mui/material'
import { fillAuctionSlots } from '@/elements/src/BidHistoryOrAuctionLeaderboard/utils'
import { BidInfo } from '@/types/api_types'
import { UnmintedNiftyMetadata } from '@/types/global'
import { HubbleBadge, Money, colors } from '@/elements/src'
import { Currency } from '@/types/payment'

export const getTableRankedAuctionData = (
  bidsData: BidInfo[],
  nifty?: UnmintedNiftyMetadata,
) => {
  const filledAuctionArray = fillAuctionSlots(
    bidsData,
    nifty?.niftyTotalNumOfEditions ?? 0,
  )

  return {
    rows: filledAuctionArray
      .slice(0, nifty!.niftyTotalNumOfEditions)
      .map((bid, index) => {
        const { user_prof: userProfile, amount, currency, Timestamp } = bid

        const originalDate = Timestamp ?? Date.now()
        const parsedDate = new Date(originalDate)
        const formattedDate = format(parsedDate, 'MMM d, yyyy h:mma')

        return {
          rank: (
            <HubbleBadge
              label={index + 1}
              sx={{
                height: '20px',
                color: colors.niftyWhite,
                backgroundColor: colors.niftyBlack,
                justifyContent: 'center',
              }}
            />
          ),
          bidder: !userProfile.name ? (
            'None'
          ) : (
            <>
              <div>
                <span>Bid placed by&nbsp;</span>
                <Typography
                  component='span'
                  sx={{ fontSize: 16, fontWeight: 600 }}
                >
                  {userProfile.name}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{ fontSize: 12, color: 'rgba(103, 104, 104, 1)' }}
                >
                  {formattedDate}
                </Typography>
              </div>
            </>
          ),
          amount: (
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              <Money
                value={[
                  amount ?? 0,
                  currency ?? nifty?.price_currency ?? Currency.USD,
                ]}
                convertFromCents={false}
              />
            </Typography>
          ),
        }
      }),
  }
}
