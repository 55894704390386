import HeartIcon from "../../../../public/images/ngmoji_thumbs_up.svg";
import TicketIcon from "../../../../public/images/ngmoji_ticketpass.svg";
import AnimeIcon from "../../../../public/images/ngmoji_anime.svg";
import ThreeDIcon from "../../../../public/images/ngmoji_3D.svg";
import MusicIcon from "../../../../public/images/ngmoji_music.svg";
import CameraIcon from "../../../../public/images/ngmoji_camera.svg";
import DollarIcon from "../../../../public/images/ngmoji_dollar.svg";
import NiftyIcon from "../../../../public/images/ngmoji_ng.svg";
import PFPIcon from "../../../../public/images/ngmoji_mfer.svg";
import OneOfOnesIcon from "../../../../public/images/ngmoji_point_up_front.svg";
import RocketIcon from "../../../../public/images/ngmoji_rocket.svg";
import EyeGlassIcon from "../../../../public/images/ngmoji_eye_glass.svg";
import { SortBy } from "@/types/marketplace";

export const MarketPlacePageQueryKey = "MarketPlacePageQueryKey";
export const MarketplacePublisherPageQueryKey =
  "MarketplacePublisherPageQueryKey";

export const PUBLISHER_NOT_FOUND_ERROR = "PUBLISHER_NOT_FOUND_ERROR";

export const POWERED_BY_NG_IMG =
  "https://media.niftygateway.com/image/upload/v1668461772/starbucks-dev/powered_by_ng.png";

// Params for when we want to default to `onSale`
export const DEFAULT_ONSALE_PARAMS = "?filters[onSale]=true&sortBy=lowest";

export const MARKETPLACE_CATEGORIES_HEADERS = [
  {
    id: 1,
    name: "Nifty Gateway Curated",
    color: "#10E3C2",
    icon: NiftyIcon,
    filter: { listingType: { curated: true }, sortBy: SortBy.recent },
  },
  {
    id: 11,
    name: "New Floor Price",
    color: "#FFB900",
    icon: EyeGlassIcon,
    filter: {
      hotDeals: "true",
      sortBy: SortBy.recent,
      filters: { onSale: true },
    },
  },
  {
    id: 13,
    name: "Trending",
    color: "#EE2E34",
    icon: RocketIcon,
    filter: { trending: "true", sortBy: SortBy.recent },
  },
  {
    id: 3,
    name: "Under $100",
    color: "#FFB900",
    icon: DollarIcon,
    filter: { price: { max: 100 }, filters: { onSale: true } },
  },
  {
    id: 10,
    name: "$100 - $250",
    color: "#FFB900",
    icon: DollarIcon,
    filter: { price: { max: 250, min: 100 }, filters: { onSale: true } },
  },
  {
    id: 12,
    name: "1 of 1s",
    color: "#8B81FF",
    icon: OneOfOnesIcon,
    filter: { editionSize: 1, sortBy: SortBy.recent },
  },
  {
    id: 2,
    name: "Most Liked",
    color: "#E975AE",
    icon: HeartIcon,
    filter: { sortBy: SortBy.mostLiked },
  },
  {
    id: 4,
    name: "PFP",
    color: "#6BD51E",
    icon: PFPIcon,
    filter: {
      categoryName: { Social: true },
      categories: { PFP: true },
    },
  },
  {
    id: 5,
    name: "Photography",
    color: "#8B81FF",
    icon: CameraIcon,
    filter: {
      categoryName: { Art: true },
      categories: { Photography: true },
    },
  },
  {
    id: 8,
    name: "Cartoon/Anime",
    color: "#FFB900",
    icon: AnimeIcon,
    filter: {
      categoryName: { Style: true },
      categories: { "Cartoon/Anime": true },
    },
  },
  {
    id: 6,
    name: "Music",
    color: "#0DC5D3",
    icon: MusicIcon,
    filter: {
      categoryName: { Art: true },
      categories: { Music: true },
    },
  },
  {
    id: 7,
    name: "3D",
    color: "#E975AE",
    icon: ThreeDIcon,
    filter: {
      categoryName: { Art: true },
      categories: { "3D Digital Art": true },
    },
  },
  {
    id: 9,
    name: "Ticket/Pass",
    color: "#6BD51E",
    icon: TicketIcon,
    filter: {
      categoryName: { Collectibles: true },
      categories: { "Ticket/Pass": true },
    },
  },
];

export const FLAG_DROP_ROUTE = process.env.NEXT_PUBLIC_REACT_APP_FLAG_DROP_ROUTE === 'true'

export const CONNECTED_APPLIED_FILTER_LABELS: Record<string, string> = {
  onSale: "On Sale",
  curated: "Nifty Gateway Curated",
  acceptingOffers: "Accepting Offers",
  Tier1: "Tier 1",
  Tier2: "Tier 2",
};

export const CONNECTED_APPLIED_FILTER_CHAINS: Record<string, string> = {
  ethereum: "ETH",
  polygon: "MATIC",
};

export const SEO_CONTENT = {
  SITE: "Nifty Gateway Studio",
  TITLE: "Marketplace - Explore Digital Art and NFTs | Nifty Gateway Studio",
  DESCR: "blockchain-powered NFT marketplace for collecting, buying, and selling unique crypto art and digital collectibles.",
  URL: "https://www.niftygateway.com/marketplace",
  IMAGE_URL:
    "https://media.niftygateway.com/image/upload/v1642195144/ng/ng-metadata-square_g75ohh.jpg",
};

export const SEO_MARKETPLACE_PUBLISHER_TITLE_PREFIX =
  "Browse NFTs published by";

export const RARITY_TOOLTIP_BODY_TEXT =
  "Rarity Rankings measure how rare an NFT is within its collection. The rank is determined by how common all the added up traits are. Click the rank on the top left of each NFT to view the rarity of traits. Rank data from Rarity Sniper.";

export const MARKET_STATISTICS_CLARIFICATION =
  "* Statistics are generated from the NFT activity on our platform";

export const MARKET_STATS_CARD_ITEMS = {
  avgSales: {
    label: "Average Sales Price",
    description:
      "Gross sales divided by the total number of NFTs sold in the collection.",
    subDescription: "* Includes primary and secondary market sales",
  },
  secondaryVolume: {
    label: "Secondary Market Volume",
    description:
      "Total volume of USD traded from resales of an NFT collection.",
    subDescription: "",
  },
  secondarySales: {
    label: "Secondary Market Sales",
    description:
      "Total number of NFTs sold from resales after the initial primary market sale.",
    subDescription: "",
  },
  uniqueOwners: {
    label: "Unique Owners",
    description: "Total number of unique holders of the NFT token.",
    subDescription: "",
  },
  floorPrice: {
    label: "Floor Price",
    description:
      "Lowest active listing price for an NFT within the collection.",
    subDescription: "",
  },
  forSale: {
    label: "For Sale",
    description: "Percentage of NFTs listed for sale within the collection.",
    subDescription: "* Number of active listings divided by collection size",
  },
  volume: {
    label: "Volume Last 30 Days",
    description: "Total volume of USD traded in the last 30 days.",
    subDescription: "",
  },
  highestActiveOffer: {
    label: "Highest Active Offer",
    description: "Highest active bid placed for an NFT within the collection.",
    subDescription: "",
  },
};

export const PRICE_ALERT_MODAL_TEXTS = {
  title: "Price Alerts",
  priceAlertLabel:
    "Get an email alert when this nifty is listed at or below this price",
  priceAlertInput: "Price ($)",
  bidAlertLabel: "Get an email alert every time a bid is placed on this nifty",
  globalOfferAlert:
    "Get an email alert every time a global bid is placed on this nifty",
  globalBidThreshold: "Set a global bid threshold to get an email alert",
  globalBidThresholdInput: "Threshold ($)",
  tradeAlert: "Get an email alert every time any edition of this nifty trades",
  save: "Save Alerts",
  delete: "Delete Alerts",
};

export const OPEN_SEA_LOGO_BLUE =
  '"https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png"';
