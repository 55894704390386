"use client";

import React, { PropsWithChildren, useState } from "react";
import { IntlProvider } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import detectLanguage from "../locales/detector";
import { localStorageAvailable } from "../locales/detector/localstorageDetector";
import { loadLocaleData, Locales } from "../locales";
import { LocaleContext } from "@/hooks/useLanguageContext";

export const LOCAL_STORAGE_LOOKUP_KEY = "preferences.lang";

const initialLocale = detectLanguage({
  order: ["querystring", "localstorage", "navigator"],
  lookupQuerystring: "lang",
  lookupLocalStorage: LOCAL_STORAGE_LOOKUP_KEY,
});

export default function LocaleProvider({ children }: PropsWithChildren) {
  const [currentLocale, setCurrentLocale] = useState(initialLocale || "en");
  const { data: messages } = useQuery(
    ["currentMessages", currentLocale],
    async () => {
      const localeData = await loadLocaleData(currentLocale);

      return localeData.default;
    }
  );
  const handleChangeLanguage = (lang: string) => {
    if (Object.keys(Locales).includes(lang)) {
      setCurrentLocale(lang);

      if (localStorageAvailable()) {
        localStorage.setItem(LOCAL_STORAGE_LOOKUP_KEY, lang);
      }
    }
  };

  return (
    <LocaleContext.Provider
      value={{
        currentLocale,
        changeLanguage: handleChangeLanguage,
      }}
    >
      <IntlProvider
        defaultLocale={currentLocale}
        locale={currentLocale}
        messages={messages}
      >
        {/* @ts-ignore */}
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}
