import { URL } from '@/app/constants'

// Add pathnames to be excluded from displaying the Zendesk Iframe
export const pathNamesToHideZenDesk = [
  '/',
  `${URL.MARQUEE_SAM_SPRATT}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE_COMMENT_ENTRY}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE_DISPLAY_COMMENT}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE_DISPLAY_DOT}/`,
  '/production/*'
]

// Add pathnames to be excluded from displaying our default Navbar
export const pathNamesToHideNavbar = [
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE_COMMENT_ENTRY}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE_DISPLAY_COMMENT}/`,
  `${URL.MARQUEE_SAM_SPRATT_BIENNALE_DISPLAY_DOT}/`,
]
