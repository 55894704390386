import { UserIdentity } from "@/types/user-identity"

declare global {
  interface Window {
    FS?: {
      shutdown(): void
      restart(): void
      identify(
        uid: string,
        userVars: { [key: string]: string | boolean | number },
      ): void
    }
  }
}

const { FS } = global?.window ?? {}

class FullStoryService {
  setUserIdentity = (identity: UserIdentity) => {
    const { consumerid, name, ...restValues } = identity

    FS?.identify(String(consumerid), { ...restValues, displayName: name })
  }

  startSessionRecording = () => {
    FS?.restart()
  }

  stopSessionRecording = () => {
    FS?.shutdown()
  }
}

export default new FullStoryService()
