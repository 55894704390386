import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import { useRouter } from 'next/navigation'
import { useQueryClient } from '@tanstack/react-query'
import getToastifyOptions from '@/utils/toastify-options'
import {
  useCreatePrimaryMarketPurchaseIntent,
  usePrimaryMarketPurchaseIntent,
  useRegenerateQuote,
  useUpdatePrimaryMarketPurchaseIntent,
} from '@/hooks/usePurchaseIntent'
import useNiftyType from '@/hooks/useNiftyType'
import useDialog from '@/hooks/useDialog'
import {
  type PaymentApiError,
  type PaymentCurrency,
  PaymentMethod,
  type ReturnUrlGeneratorConfig,
  getDefaultPaymentCurrency,
} from '@/types/payment-types'
import useDefaultPaymentMethod from '@/hooks/useDefaultPaymentMethod'
import { LoadingSpinner, Money, NiftyCard, Table, colors } from '@/elements/src'
import { Currency } from '@/types/payment'
import RankingSimulatorContainer from '@/elements/src/RankingSimulatorContainer'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import SuggestedBidsContainer from '@/elements/src/SuggestedBidsContainer'
import MinBidsCalculationContainer from '@/elements/src/MinBidsCalculationContainer'
import { getQuoteBreakdownItems } from '../../checkout/[contractAddress]/[niftyType]/lineItems'
import PaymentsWeb3Provider from '../../checkout/_components/PaymentsWeb3Provider'
import DollarNumberFormat from '../../(legacy-checkout)/_components/DollarNumberFormat'
import EtherNumberFormat from '../../listing/_components/EtherNumberFormat'
import {
  useAuction,
  useAuctionMinimumViableBid,
  useBidHistory,
} from '../../(legacy-checkout)/_hooks/useAuction'
import { getReturnUrl } from '../_utils/getReturnUrl'
import { RankedAuctionBidHistoryQueryKey } from '../../itemdetail/primary/[contractAddress]/[niftyType]/constants'
import PaymentForm from '../../checkout/_components/PaymentForm'
import PaymentSelect from '../../checkout/_components/PaymentSelect'
import { RECOVERABLE_ERROR_TYPES } from '../constants'
import { safeParseFloat } from '../_utils/safeParseFloat'
import { getTableRankedAuctionData } from '../_utils/getTableRankedAuctionData'
import { OUTBID_MESSAGE } from '../../checkout/constants'

interface UrlParams {
  contractAddress: string
  niftyType: string
}

interface AuctionCheckoutProps {
  contractAddress: string
  niftyType: string
  purchaseIntentId: string
  hideHeading?: boolean
  openDialog: ReturnType<typeof useDialog>['openDialog']
  children?: React.ReactNode
  preventRedirect?: boolean
  onPurchaseIntentStatus?: (status: 'init' | 'success' | 'error' | null) => void
  onPaymentStatus?: (
    status: string,
    remove: () => void,
    quantity: number,
  ) => void
  onPaymentSuccess?: (paymentAmount: string, paymentCurrency: Currency) => void
  onPaymentError?: (err?: unknown) => void
  onPaymentInit?: () => void
  closeModal: () => void
  generateReturnUrl?: (config: ReturnUrlGeneratorConfig) => string
}

const redirectToItemDetail = (
  { contractAddress, niftyType }: UrlParams,
  router: ReturnType<typeof useRouter>,
) =>
  setTimeout(() => {
    router.replace(`/itemdetail/primary/${contractAddress}/${niftyType}`)
  }, 4000)

function AuctionCheckout({
  purchaseIntentId,
  contractAddress,
  niftyType,
  hideHeading = false,
  children,
  openDialog,
  onPaymentInit,
  onPaymentSuccess,
  onPaymentError,
  onPurchaseIntentStatus,
  preventRedirect = false,
  closeModal,
  generateReturnUrl,
}: AuctionCheckoutProps) {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { data: nifty } = useNiftyType({
    contractAddress,
    niftyType,
  })
  const theme = useTheme()
  const isMobile = useMediaQueryState(theme.breakpoints.down('md'))

  const { bidHistory, refetch: refetchBidHistory } = useBidHistory({
    contractAddress,
    niftyType,
    size: 100,
    current: 1,
    refetchInterval: 5_000,
  })

  const {
    data: maxBidData = { max_bid: 0, min_increment: 0 },
    refetch: refetchMaxBidData,
  } = useAuction({ contractAddress, niftyType })
  const { auctionMinimumViableBidData, refetch: refetchMinBidData } =
    useAuctionMinimumViableBid(contractAddress, niftyType)
  const dropType = nifty?.primary_sale_type
  const isRankedAuction = (nifty?.niftyTotalNumOfEditions ?? 0) > 1
  const { purchaseIntent, stripePaymentIntent, paymentRequired, refetch } =
    usePrimaryMarketPurchaseIntent(purchaseIntentId, { dropType })
  const { updatePurchaseIntent, isLoading: isUpdating } =
    useUpdatePrimaryMarketPurchaseIntent()
  const { defaultPaymentMethod } = useDefaultPaymentMethod()
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    purchaseIntent?.paymentMethod ?? defaultPaymentMethod,
  )
  const [paymentCurrency, setPaymentCurrency] = useState<PaymentCurrency>(
    getDefaultPaymentCurrency(
      purchaseIntent?.paymentMethod ?? defaultPaymentMethod,
    ),
  )

  const [updateFailed, setUpdateFailed] = useState(false)
  const [purchaseComplete, setPurchaseComplete] = useState(false)
  const regenerateQuote = useRegenerateQuote(purchaseIntentId, dropType)
  const [debouncedAmount, setDebouncedAmount] = useState(
    purchaseIntent?.bidAmount,
  )

  const refetchAllBidData = useCallback(() => {
    void refetchMaxBidData()
    void refetchMinBidData()
    void refetchBidHistory()
  }, [refetchMaxBidData, refetchMinBidData, refetchBidHistory])

  const debounce = useCallback(
    _debounce((value: string) => {
      setDebouncedAmount(value)
    }, 500),
    [],
  )

  const afterSubmit = useCallback(() => {
    setPurchaseComplete(true)
    void refetch({ cancelRefetch: true })
  }, [setPurchaseComplete, refetch])

  const lineItems = useMemo(
    () => getQuoteBreakdownItems(nifty?.is_collecting_tax ?? false),
    [nifty],
  )

  const shouldUpdatePurchaseIntent =
    !purchaseComplete &&
    !isUpdating &&
    Boolean(paymentRequired) &&
    purchaseIntent?.state === 'INITIATED' &&
    // TODO: Add update condition(s) for destination changes
    // the payment method has changed
    (paymentMethod !== purchaseIntent.paymentMethod ||
      // OR the payment currency has changed
      paymentCurrency !== purchaseIntent.paymentCurrency ||
      safeParseFloat(debouncedAmount ?? '0') !==
        safeParseFloat(purchaseIntent.bidAmount || '0') ||
      // OR paymentMethod is a Stripe payment method, AND we don't have a Stripe PI
      ([PaymentMethod.BNPL, PaymentMethod.CARD].includes(paymentMethod) &&
        !stripePaymentIntent))

  const handlePurchaseIntentError = useCallback(
    (e?: PaymentApiError) => {
      const { errorType, message } = e ?? {}
      // Prevent further update attempts until user changes something on the form
      setUpdateFailed(true)
      switch (errorType) {
        case 'AUCTION_BID_INVALID': {
          refetchAllBidData()
          openDialog(OUTBID_MESSAGE)
          return
        }
        default: {
          // TODO: Determine if we're dealing with a fatal error before redirect
          if (onPaymentError) onPaymentError(e)
          if (!preventRedirect) {
            redirectToItemDetail({ contractAddress, niftyType }, router)
          }
          openDialog(
            message ?? (
              <>
                There was a problem creating your purchase.
                <br />
                Redirecting you to the Item Detail page.
              </>
            ),
          )
        }
      }
    },
    [
      contractAddress,
      nifty,
      niftyType,
      openDialog,
      onPaymentError,
      preventRedirect,
      refetchAllBidData,
    ],
  )

  useEffect(() => {
    if (
      !purchaseIntentId ||
      !dropType ||
      !shouldUpdatePurchaseIntent ||
      updateFailed ||
      safeParseFloat(debouncedAmount ?? '0') <
        (auctionMinimumViableBidData?.min_bid_amount ?? Infinity)
    )
      return

    if (onPurchaseIntentStatus) onPurchaseIntentStatus('init')

    updatePurchaseIntent(
      {
        dropType,
        id: purchaseIntentId,
        // Don't attempt to set payment method for free drops
        payment_method: paymentRequired ? paymentMethod : PaymentMethod.NONE,
        payment_currency: paymentCurrency,
        bid_amount: debouncedAmount,
        bid_currency: nifty.price_currency,
      },
      {
        onSuccess: () => {
          if (onPurchaseIntentStatus) onPurchaseIntentStatus('success')
        },
        onError: (e) => {
          if (onPurchaseIntentStatus) onPurchaseIntentStatus('error')
          handlePurchaseIntentError(e)
        },
      },
    )

    refetchAllBidData()
  }, [
    shouldUpdatePurchaseIntent,
    purchaseIntentId,
    paymentMethod,
    paymentCurrency,
    paymentRequired,
    updatePurchaseIntent,
    handlePurchaseIntentError,
    updateFailed,
    dropType,
    onPurchaseIntentStatus,
    debouncedAmount,
    refetchAllBidData,
  ])

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Try to recover from prior error now that user has made changes to form
    setUpdateFailed(false)
    debounce(e.target.value)
  }

  return (
    <Box
      sx={{
        display: isMobile ? 'inline-block' : 'flex',
        gap: '100px',
        width: '100%',
      }}
    >
      <Box sx={{ width: isMobile ? '100%' : '50%' }}>
        <Stack
          direction='row'
          sx={{
            marginTop: '40px',
            width: isMobile ? '300px' : '100%',
            height: isMobile ? '380px' : '500px',
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            border: '1px solid rgba(230, 230, 230, 1)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderRadius: '16px',
          }}
        >
          <div
            id='inner-image-container'
            style={{
              width: isMobile ? '250px' : '360px',
              height: isMobile ? '250px' : '360px',
              position: 'relative',
              marginLeft: isMobile ? '10px' : '30px',
              marginRight: isMobile ? '20px' : '30px',
              marginBottom: '12px',
            }}
          >
            {_isEmpty(nifty) ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                width={200}
                height={200}
              />
            ) : (
              <NiftyCard
                asset={{
                  src: nifty.niftyImageURL,
                }}
              />
            )}
          </div>
          {!_isEmpty(nifty) && (
            <div
              style={{
                alignContent: 'flex-start',
                marginLeft: isMobile ? '10px' : '30px',
              }}
            >
              <Typography
                sx={{ fontSize: isMobile ? 15 : 20, fontWeight: 600 }}
              >
                {nifty.niftyTitle}
              </Typography>
              <Typography
                sx={{
                  fontSize: isMobile ? 12 : 16,
                  fontWeight: 400,
                  color: 'rgba(1, 3, 4, 0.6)',
                  marginTop: '8px',
                }}
              >
                {nifty.artist_name}
              </Typography>
              <Divider
                sx={{
                  border: '0.74px solid rgba(230, 230, 230, 1)',
                  my: '10px',
                  width: isMobile ? '250px' : '360px',
                }}
              />
              <Typography
                sx={{
                  fontSize: isMobile ? 12 : 16,
                  fontWeight: 400,
                  color: colors.niftyBlack,
                }}
              >
                High bid&nbsp;&nbsp;&nbsp;
                <Money
                  value={[
                    nifty?.isSilentAuction
                      ? (nifty?.price_amount ?? 0)
                      : maxBidData.max_bid,
                    nifty?.price_currency ?? Currency.USD,
                  ]}
                  convertFromCents={false}
                />
              </Typography>
            </div>
          )}
        </Stack>
        {isRankedAuction && Boolean(bidHistory) && !nifty?.isSilentAuction ? (
          <Box
            sx={{
              marginTop: isMobile ? '25px' : '40px',
              maxHeight: '250px',
              overflowY: 'auto',
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
              Bid rankings
            </Typography>

            <Table
              data={getTableRankedAuctionData(bidHistory ?? [], nifty)}
              rowheight={80}
              twoTone={false}
            />
          </Box>
        ) : null}
      </Box>
      <Stack
        sx={{
          width: isMobile ? '100%' : '50%',
          marginTop: isMobile ? '25px' : '40px',
        }}
      >
        {!isMobile && (
          <Typography variant='h2' sx={{ marginBottom: '25px' }}>
            Place a bid for {nifty?.niftyTitle}
          </Typography>
        )}
        <Typography variant='h3'>Bid Amount</Typography>
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus -- Auto focus is a desired functionality
          autoFocus
          required
          id='amount'
          name='amount'
          margin='normal'
          variant='outlined'
          onChange={onHandleChange}
          defaultValue={purchaseIntent?.bidAmount}
          inputProps={{
            'data-testid': 'confirm-bid-input',
          }}
          InputProps={{
            'aria-label': 'Bid amount',
            inputComponent:
              nifty?.price_currency === Currency.USD
                ? DollarNumberFormat
                : EtherNumberFormat,
          }}
        />
        <Typography
          sx={{ mb: 2, fontSize: 'xsmall' }}
          variant='body1'
          color={colors.grayText2}
          component='div'
        >
          Min Bid:&nbsp;
          <Money
            value={[
              nifty?.isSilentAuction
                ? (nifty?.price_amount ?? 0)
                : (auctionMinimumViableBidData?.min_bid_amount ?? 0),
              auctionMinimumViableBidData?.min_bid_currency ?? Currency.USD,
            ]}
            convertFromCents={false}
          />
        </Typography>
        {!isRankedAuction &&
          Boolean(bidHistory?.length) &&
          !nifty?.isSilentAuction && (
            <Box sx={{ marginBottom: '16px' }}>
              <MinBidsCalculationContainer
                minBid={auctionMinimumViableBidData?.min_bid_amount ?? 0}
                bidCurrency={
                  auctionMinimumViableBidData?.min_bid_currency ?? Currency.USD
                }
                highestBid={maxBidData.max_bid}
                bidIncrement={maxBidData.min_increment}
              />
            </Box>
          )}
        {isRankedAuction && !nifty?.isSilentAuction ? (
          <Box sx={{ marginBottom: '16px' }}>
            <RankingSimulatorContainer
              sortedBids={bidHistory ?? []}
              lowestDisplayRank={
                nifty?.niftyTotalNumOfEditions ?? bidHistory?.length ?? 0
              }
            />
          </Box>
        ) : null}
        {isRankedAuction && !nifty?.isSilentAuction ? (
          <Box sx={{ marginBottom: '16px' }}>
            <SuggestedBidsContainer
              bidIncrement={maxBidData.min_increment}
              bidCurrency={
                auctionMinimumViableBidData?.min_bid_currency ?? Currency.USD
              }
              firstPlaceBid={maxBidData.max_bid + maxBidData.min_increment}
              leaderboardMinBid={
                auctionMinimumViableBidData?.min_bid_amount ?? 0
              }
            />
          </Box>
        ) : null}
        {paymentRequired ? (
          <PaymentSelect
            MenuProps={hideHeading ? { sx: { zIndex: 53001 } } : undefined}
            purchaseIntent={purchaseIntent}
            paymentMethod={paymentMethod}
            setPaymentMethod={(pm) => {
              // Try to recover from prior error now that user has made changes to form
              setUpdateFailed(false)
              setPaymentMethod(pm)
            }}
            paymentCurrency={paymentCurrency}
            setPaymentCurrency={setPaymentCurrency}
          />
        ) : null}
        <PaymentForm
          isPrimaryMarket
          nifty={nifty}
          afterSubmit={afterSubmit}
          canSubmit={!updateFailed && !isUpdating}
          isUpdating={isUpdating}
          invoiceSectionTitle='Confirm Bid'
          lineItems={lineItems}
          openDialog={openDialog}
          paymentMethod={purchaseIntent?.paymentMethod ?? defaultPaymentMethod}
          paymentCurrency={paymentCurrency}
          setPaymentCurrency={setPaymentCurrency}
          purchaseIntentId={purchaseIntentId}
          regenerateQuote={regenerateQuote}
          submitButtonText='Confirm Bid'
          dropType={nifty?.primary_sale_type}
          dropEndTime={nifty?.scheduledEndDateTime}
          usePaymentInfo={usePrimaryMarketPurchaseIntent}
          bottomAnchorChildren={children}
          disablePurchase={
            safeParseFloat(debouncedAmount ?? '0') <
            (auctionMinimumViableBidData?.min_bid_amount ??
              nifty?.price_amount ??
              Infinity)
          }
          generateReturnUrl={(config) => {
            if (generateReturnUrl) return generateReturnUrl(config)
            const defaultUrl = getReturnUrl(contractAddress, niftyType)
            return `${defaultUrl}?pm=${config.paymentMethod}&piid=${config.purchaseIntentId}&pid=${config.paymentId}`
          }}
          onPaymentInit={onPaymentInit}
          onPaymentSuccess={() => {
            // Trigger a refetch for bid history table data
            // The endpoint takes up to 5 seconds to reflect the new bid
            setTimeout(() => {
              void queryClient.invalidateQueries([
                RankedAuctionBidHistoryQueryKey,
                contractAddress,
                niftyType,
              ])
            }, 5000)
            void refetchBidHistory()
            toast.success(
              <>
                Your&nbsp;
                <Money
                  value={[
                    debouncedAmount ?? 0,
                    nifty?.price_currency ?? Currency.USD,
                  ]}
                  convertFromCents={false}
                />
                &nbsp;bid for {nifty?.niftyTitle} was successfully submitted!
              </>,
              getToastifyOptions('success'),
            )
            closeModal()
            onPaymentSuccess?.(
              debouncedAmount ?? '0',
              nifty?.price_currency ?? Currency.USD,
            )
          }}
          onPaymentError={onPaymentError}
          onErrorConfirm={(errorType) => {
            // Don't close modal unless the specific error is not in the set of known-recoverable error types
            if (!RECOVERABLE_ERROR_TYPES.includes(errorType)) {
              closeModal()
            }
            // User tried to submit (previously valid) bid amount but was outbid beforehand
            if (errorType === 'AUCTION_BID_INVALID') {
              refetchAllBidData()
            }
          }}
        />
      </Stack>
    </Box>
  )
}
interface ContainerProps {
  contractAddress: string
  niftyType: string
  openDialog: ReturnType<typeof useDialog>['openDialog']
  purchaseIntentId: string
  closeModal: () => void
  generateReturnUrl?: (config: ReturnUrlGeneratorConfig) => string
  onPaymentSuccess?: (paymentAmount: string, paymentCurrency: Currency) => void
  preventRedirect?: boolean
}

function AuctionCheckoutContainer({
  contractAddress,
  niftyType,
  openDialog,
  purchaseIntentId,
  closeModal,
  generateReturnUrl,
  onPaymentSuccess,
  preventRedirect,
}: ContainerProps) {
  const [createdPurchaseIntent, setCreatedPurchaseIntent] = useState(false)
  const { data: nifty, isLoading: isNiftyLoading } = useNiftyType({
    contractAddress,
    niftyType,
  })
  const { createPurchaseIntent } = useCreatePrimaryMarketPurchaseIntent({
    contractAddress,
    niftyType,
  })

  const { purchaseIntent, isLoading } = usePrimaryMarketPurchaseIntent(
    purchaseIntentId,
    { dropType: nifty?.primary_sale_type },
  )

  useEffect(() => {
    // Don't create a new purchase intent if we already have one or we're still loading
    if (
      createdPurchaseIntent ||
      !nifty ||
      ((Boolean(purchaseIntent) || isLoading) && Boolean(purchaseIntentId))
    )
      return
    setCreatedPurchaseIntent(true)
    void createPurchaseIntent({
      contractAddress,
      niftyType,
      nifty_builder: nifty.id,
      requested_quantity: 1,
      dropType: nifty.primary_sale_type,
    })
  }, [
    purchaseIntent,
    isLoading,
    nifty,
    createPurchaseIntent,
    purchaseIntentId,
    createdPurchaseIntent,
    contractAddress,
    niftyType,
  ])

  return (
    <PaymentsWeb3Provider>
      {!purchaseIntentId || isNiftyLoading ? (
        <LoadingSpinner />
      ) : (
        <AuctionCheckout
          openDialog={openDialog}
          contractAddress={contractAddress}
          niftyType={niftyType}
          purchaseIntentId={purchaseIntentId}
          closeModal={closeModal}
          generateReturnUrl={generateReturnUrl}
          preventRedirect={preventRedirect}
          onPaymentSuccess={onPaymentSuccess}
        />
      )}
    </PaymentsWeb3Provider>
  )
}

function WrappedAuctionCheckoutContainer(
  props: Omit<AuctionCheckoutProps, 'openDialog'>,
) {
  const { RenderDialog, openDialog } = useDialog()
  return (
    <>
      <AuctionCheckoutContainer {...props} openDialog={openDialog} />
      <RenderDialog hideCancelButton />
    </>
  )
}

export default WrappedAuctionCheckoutContainer
