import React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { EthIcon } from '@/elements/src'
import { InputBaseComponentProps } from '@mui/material'

const EtherNumberFormat = React.forwardRef<
  React.ElementType,
  InputBaseComponentProps
>((props, ref) => {
  const { defaultValue, name, value, onChange, ...other } = props

  return (
    <>
      {(!!value || !!defaultValue) && (
        <EthIcon size={16} style={{ margin: '0 -12px 0 12px' }} />
      )}
      <NumberFormat
        {...other}
        value={value}
        defaultValue={defaultValue as NumberFormatProps['defaultValue']}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange?.({
            target: {
              name,
              value: values.value,
            },
          } as React.ChangeEvent<HTMLInputElement>)
        }}
        thousandSeparator
        decimalScale={18}
        isNumericString
        allowNegative={false}
      />
    </>
  )
})
EtherNumberFormat.displayName = 'EtherNumberFormat'
export default EtherNumberFormat
