'use client'

import Script from 'next/script'
import { useUser } from '@/providers/UserContext'
import { useEffect, useState } from 'react'
import { setUuid, getUuid } from '@/utils/sardine'

const isDev = process.env.NEXT_PUBLIC_REACT_APP_WEB3_CHAIN_ENVIRONMENT === 'development'

// Loading strategy is set to afterInteractive: Load early but after some hydration on the page occurs.
const Sardine = () => {
  const { user } = useUser()
  const userEmail = user.user_email || ''
  const sardineHost = isDev ? 'api.dev.sardine.ai' : 'api.sardine.ai'
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [uuid] = useState(() => {
    const curCookieValue = getUuid()

    if (!curCookieValue) {
      return setUuid()
    }

    return curCookieValue
  })

  useEffect(() => {
    if (
      scriptLoaded &&
      global?.window?._Sardine &&
      typeof uuid === 'string' &&
      uuid.length > 0 &&
      typeof userEmail === 'string' &&
      userEmail.length > 0
    ) {
      global?.window?._Sardine.createContext({
        clientId: process.env.NEXT_PUBLIC_REACT_APP_SARDINE_CLIENT_ID,
        sessionKey: uuid,
        userIdHash: userEmail,
        flow: location.pathname,
        environment: isDev ? 'development' : 'production',
        parentElement: document.body,
      })
    }
  }, [uuid, userEmail, scriptLoaded])

  return (
    <Script
      src={`https://${sardineHost}/assets/loader.min.js`}
      type='text/javascript'
      strategy='afterInteractive'
      onLoad={() => setScriptLoaded(true)}
    />
  )
}

export default Sardine
