import React from "react";
import { Box, Chip, ListSubheader, Typography, styled } from "@mui/material";
import { colors } from "@/elements/src";
import { MARKETPLACE_CATEGORIES_HEADERS } from "@/app/(ng-core)/marketplace/constants";

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;

  .searchcategories--chip {
    margin: 4px;
    font-size: 14px;
    font-weight: bold;
    color: ${colors.niftyBlack};
  }
`;

type Category = {
  name: string;
  filter: { [key: string]: unknown };
};

type SearchCategoriesProps = {
  onClickCategory: (category: Category) => void;
};

function SearchCategories({ onClickCategory }: SearchCategoriesProps) {
  return (
    <>
      <ListSubheader color="inherit">
        <Typography variant="h4" gutterBottom>
          Categories
        </Typography>
      </ListSubheader>
      <StyledBox>
        {MARKETPLACE_CATEGORIES_HEADERS.map((category, index) => (
          <Chip
            key={index}
            variant="outlined"
            label={category.name}
            className="searchcategories--chip"
            onClick={() => onClickCategory(category)}
          />
        ))}
      </StyledBox>
    </>
  );
}

export default SearchCategories;
