'use client'

import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { usePathname, useRouter } from 'next/navigation'
import { NavBar, BannerCarousel, BannerItem, LevelBadge } from '@/elements/src'
import { URL } from '@/app/constants'
import FullStoryService from '@/services/FullStoryService'
import { getActiveStyles, getCTABanner } from '@/api/navbar'
import { postLogout } from '@/api/auth'
import { useWeb3React } from '@web3-react/core'
import { useLanguageContext } from '@/hooks/useLanguageContext'
import { User } from '@/types/global'
import { useAppDispatch } from '@/redux/store'
import { toggleDrawer } from '@/redux/reducers/ui'
import { useAnalytics } from '@/providers/AnalyticsContext'
import { useQuery } from '@tanstack/react-query'
import * as webTokens from '@hubble/tokens/web'
import useAuth from '@/hooks/useAuth'
import useHeaderNavHeight from '@/hooks/useHeaderNavHeight'
import { useCollectorAppreciation } from '@/hooks/useCollectorAppreciation'
import { isCAMRewardsEnabled } from '@/utils/is-cam-rewards-enabled'
import { getLibrary } from '@/utils/web3'
import { useUser } from '@/providers/UserContext'
import { Locales } from '../../../locales'
import {
  paths,
  externalLinks,
  useUserLinks,
  useNavbarLinks,
  getHomePath,
} from './links'
import DynamicBanner from './Banners'
import { ActiveLogo } from '../NavBar/types'
import { RenderSearch } from './ConnectedSearch'
import { pathNamesToHideNavbar } from '@/settings'
import doesPathMatch from '@/utils/does-path-match'

interface ConnectedNavBarProps {
  hideBanner?: boolean
}

const NGS_STUDIO_ACTIVE_LOGO: ActiveLogo = {
  imageUrl: '',
  margin: '12px',
  width: 'auto',
  height: '65px',
  hideDefaultLogo: false,
  backgroundColor: webTokens.color.base.black,
  textColor: webTokens.color.base.white,
}

const animTimeFn = (bannerItem: BannerItem, index: number) => {
  const now = new Date()
  const utcTime = new Date(now.toUTCString())
  const startTime = bannerItem.startTime as Date
  const endTime = bannerItem.endTime as Date

  if (startTime > utcTime) {
    requestAnimationFrame(() => animTimeFn(bannerItem, index))
  } else if (utcTime >= startTime && utcTime <= endTime) {
    requestAnimationFrame(() => animTimeFn(bannerItem, index))

    // @ts-ignore
    bannerCarouselRef.current?.showBanner(bannerItem, index)
  } else {
    // @ts-ignore
    bannerCarouselRef.current?.hideBanner(bannerItem, index)
  }
}

const mapUserProfile = (user: User) => {
  return {
    userName: user.profile_url,
    userDisplayName: user.name || `@${user.profile_url}`,
    userProfileUrl: user.profile_pic_url,
  }
}

const NGS_ENABLED = true
const FLAG_INTL_MESSAGES =
  process.env.NEXT_PUBLIC_REACT_APP_FLAG_INTL_MESSAGES === 'true'

export const ConnectedNavBar = ({
  hideBanner = false,
}: ConnectedNavBarProps) => {
  const bannerCarouselRef = React.useRef()
  const appBarRef = React.useRef<HTMLDivElement>()

  const dispatch = useAppDispatch()
  const { user, publisherPermissions } = useUser()
  const auth = useAuth()
  const { currentLocale, changeLanguage } = useLanguageContext()
  const CurrentLocaleFlag =
    Locales[currentLocale as keyof typeof Locales]?.flag || Locales.en.flag
  const currentPath = usePathname()
  const history = useRouter()
  const { logEvent } = useAnalytics()
  const { account: connectedWalletAddress } = useWeb3React()
  const { setHeaderNavHeight } = useHeaderNavHeight()
  const { data: activeLogo } = useQuery(['activeStyles', 'logo'], async () => {
    if (NGS_ENABLED) {
      return NGS_STUDIO_ACTIVE_LOGO
    }

    const { data } = await getActiveStyles()
    return data?.logo ? JSON.parse(data.logo) : null
  })
  const { data: ctaBannerData, isLoading } = useQuery(
    ['ctaBanner'],
    async () => {
      const { data } = await getCTABanner()

      const customBannersList = (data ?? []).map((cta) =>
        DynamicBanner(cta, logEvent),
      )

      if (customBannersList.length > 0) {
        customBannersList.forEach((bannerItem, index) => {
          if (bannerItem.startTime && bannerItem.endTime) {
            animTimeFn(bannerItem, index)
          }
        })
      }

      return customBannersList
    },
  )
  const { data: camData } = useCollectorAppreciation()

  const handleClickLink = (label: string) => {
    if (label === 'Help center') {
      logEvent?.('Help Center Clicked', 'Other')
    }
  }

  const handleClickCustomLogo = (pos: number) =>
    logEvent?.('Additional Logo', 'Navigation', {
      collectionSlug: activeLogo?.collectionUrl,
      pos,
    })

  const handleLogout = () => {
    logEvent?.('User Logged Out', 'Other')
    postLogout(auth.values.credentials)
    auth?.update?.({ credentials: {} })
    FullStoryService.stopSessionRecording()

    localStorage?.clear()

    history.push('/')
  }

  const isFetching = user.isLoading || auth.isRefreshing || isLoading
  const defaultNavbarLinks = useNavbarLinks()

  const navbarLinks = defaultNavbarLinks
  const mappedUser = mapUserProfile(user)
  const userLinks = useUserLinks(mappedUser)
  const pathsToShow = { ...paths, home: getHomePath() }
  const hideBannerCarousel =
    isLoading ||
    !ctaBannerData ||
    hideBanner ||
    (Array.isArray(ctaBannerData) && ctaBannerData.length === 0)

  // Check if we should render the Navbar
  const hideNavBar = doesPathMatch(currentPath, pathNamesToHideNavbar)
  if (hideNavBar) {
    return null
  }

  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <NavBar
          ref={appBarRef as React.Ref<HTMLDivElement>}
          activeLogo={activeLogo}
          user={mappedUser}
          externalLinks={externalLinks}
          paths={pathsToShow}
          links={navbarLinks}
          userLinks={userLinks}
          isLoggedIn={auth.isLoggedIn()}
          handleLogout={handleLogout}
          handleClickLink={handleClickLink}
          handleClickCustomLogo={handleClickCustomLogo}
          isFetching={isFetching}
          currentPath={currentPath}
          searchComponent={(handleClose, color) => (
            <RenderSearch handleCloseMenu={handleClose} color={color} />
          )}
          connectedWalletAddress={connectedWalletAddress ?? undefined}
          banner={
            hideBannerCarousel ? null : (
              <BannerCarousel
                ref={bannerCarouselRef}
                items={ctaBannerData}
                onResize={setHeaderNavHeight}
              />
            )
          }
          currentLanguageLabel={CurrentLocaleFlag}
          languagesOptions={
            FLAG_INTL_MESSAGES
              ? Object.keys(Locales).map((key) => ({
                  label: Locales[key as keyof typeof Locales].label,
                  onClick: () => changeLanguage(key),
                }))
              : undefined
          }
          publisherPermissions={publisherPermissions}
          publishersPath={URL.PUBLISHERS_V2}
          toggleDrawer={() => dispatch(toggleDrawer())}
          isWalletDrawerEnabled={false}
          navbarPromo={
            isCAMRewardsEnabled() ? (
              <LevelBadge inNav level={camData?.tier || '--'} />
            ) : null
          }
        />
      </Web3ReactProvider>
    </>
  )
}

export default ConnectedNavBar
