import { useRouter } from 'next/navigation'
import {
  Search,
  Typography,
  SimpleErrorBoundary,
  ExploreSearch,
} from '@/elements/src'
import { Alert } from '@mui/material'

import { useAnalytics } from '@/providers/AnalyticsContext'
import { useUser } from '@/providers/UserContext'
import useExploreFeatureFlag from '@/hooks/useExploreFeatureFlag'

import SearchResults from './components/search-results'
import SearchSuggests from './components/search-suggests'

interface RenderSearchProps {
  handleCloseMenu: () => void
  color?: string
}

const ConnectedSearch: React.FC<RenderSearchProps> = ({
  handleCloseMenu,
  color,
}) => {
  const history = useRouter()
  const { logEvent } = useAnalytics()
  const { user } = useUser()
  return (
    <Search
      className='searchIcon'
      onSearchStarted={() => {
        logEvent?.('Universal Search Started', 'Search', {
          userId: user.id || '',
          timestamp: new Date().toISOString(),
        })
      }}
      onSearchEnded={() => {
        logEvent?.('Universal Search Ended', 'Search', {
          userId: user.id || '',
          timestamp: new Date().toISOString(),
        })
      }}
      color={color}
    >
      {({ query, closeSearch }) => (
        <SimpleErrorBoundary
          fallback={
            <Alert severity='error' style={{ margin: 20 }}>
              <Typography mr={1} variant='body1'>
                Please come back and try again later.
              </Typography>
            </Alert>
          }
        >
          <div
            style={{
              color: 'black !important',
              paddingBottom: 0,
            }}
          >
            {!query ? (
              <SearchSuggests query={query} closeSearch={closeSearch} />
            ) : (
              <>
                <SearchResults
                  query={query || ''}
                  onClickResult={(
                    _: React.MouseEvent<HTMLDivElement, MouseEvent>,
                    path: string,
                  ) => {
                    logEvent?.('Universal Search Used', 'Search', {
                      searchPath: path || '',
                      query: query || '',
                      userId: user.id || '',
                      timestamp: new Date().toISOString(),
                    })
                    history.push(path)
                    window.requestAnimationFrame(() => {
                      closeSearch()
                      handleCloseMenu()
                    })
                  }}
                />
              </>
            )}
          </div>
        </SimpleErrorBoundary>
      )}
    </Search>
  )
}

export const RenderSearch = ({ handleCloseMenu, color }: RenderSearchProps) => {
  const isExplorePageEnabled = useExploreFeatureFlag()

  return isExplorePageEnabled ? (
    <ExploreSearch onCancel={handleCloseMenu} color={color} />
  ) : (
    <ConnectedSearch handleCloseMenu={handleCloseMenu} color={color} />
  )
}

export default ConnectedSearch
