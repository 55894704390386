import { TraitObject, TraitOption } from '@/types/global'
import { TraitGroup } from './types'
// Map returned `trait_values` into `TraitGroup`
//
// Before:
// [ { trait: { name: 'Background' }, value: 'Blue' }, { trait: { name: 'Background' }, value: 'Pink' } ]
//
// After:
// [{ name: "Background", values: [ { name: "Background", value: "Blue" }, { name: "Background", value: "Pink" } ] }]

//
const DISABLE_GLOBAL_OFFER_BY_TRAIT = false

export const createTraitGroups = (traits: TraitObject[]): TraitGroup[] => {
  if (DISABLE_GLOBAL_OFFER_BY_TRAIT) return []
  const traitMap = traits.reduce<Map<string, TraitGroup>>(
    (traitGroupMap, traitObj) => {
      const traitName = traitObj.trait.name
      const valueObj: TraitOption = { name: traitName, value: traitObj.value }
      const existing = traitGroupMap.get(traitObj.trait.name)
      const toUpdate: TraitGroup = existing
        ? {
            ...existing,
            values: [...existing.values, valueObj],
          }
        : {
            name: traitName,
            values: [valueObj],
          }
      traitGroupMap.set(traitName, toUpdate)
      return traitGroupMap
    },
    new Map<string, TraitGroup>(),
  )

  return Array.from(traitMap.values())
}
