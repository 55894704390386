'use client'

import { useRef } from 'react'
import { Provider } from 'react-redux'
import { createStore, AppStore } from '@/redux/store'

export default function ReduxProvider({
  children
}: {
  children: React.ReactNode
}) {
  const storeRef = useRef<AppStore>()
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = createStore()
  }

  return <Provider store={storeRef.current}>{children}</Provider>
}