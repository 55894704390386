import { DetectorOptions } from './types'

let hasLocalStorageSupport: boolean | null = null

export const localStorageAvailable = () => {
  if (hasLocalStorageSupport !== null) return hasLocalStorageSupport

  try {
    hasLocalStorageSupport = window.localStorage !== null
    const testKey = 'boo'
    window.localStorage.setItem(testKey, 'foo')
    window.localStorage.removeItem(testKey)
  } catch (e) {
    hasLocalStorageSupport = false
  }
  return hasLocalStorageSupport
}

export default {
  detect(options: DetectorOptions) {
    if (options.lookupLocalStorage && localStorageAvailable()) {
      const savedLang = window.localStorage.getItem(options.lookupLocalStorage)

      if (savedLang) return savedLang
    }

    return null
  },
}
