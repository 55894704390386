import { SearchResultItem } from "@/types/search";

const STATE_KEY_RECENT_SEARCHES = "recent-searches";

type SearchRecentItem = SearchResultItem & {
  path: string;
  type: string;
};

const getRecentSearch = (): SearchRecentItem[] => {
  try {
    const recentSearchStr = localStorage.getItem(STATE_KEY_RECENT_SEARCHES);
    const recentSearches = JSON.parse(recentSearchStr!);
    return recentSearches || [];
  } catch (error) {
    return [];
  }
};

const setRecentSearch = ({
  path,
  type,
  item,
}: {
  path: string;
  type: string;
  item: SearchResultItem;
}) => {
  const recentSearches = getRecentSearch();
  const filteredRecentSearches = recentSearches.filter(
    (recentItem) => recentItem.id !== item.id
  );
  const length = filteredRecentSearches.unshift({ ...item, path, type });
  if (length > 5) filteredRecentSearches.pop();

  try {
    localStorage.setItem(
      STATE_KEY_RECENT_SEARCHES,
      JSON.stringify(filteredRecentSearches)
    );
  } catch (err) {}
};

const getRecentSearchCount = () => {
  const recentSearches = getRecentSearch();
  return recentSearches.length;
};

export { setRecentSearch, getRecentSearch, getRecentSearchCount };
