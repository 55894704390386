import Link from 'next/link'

import { Button, Text } from '@hubble/web'
import { Stack } from '@mui/material'

import { useUser } from '@/providers/UserContext'

import OfferStatus from './OfferStatus'

import { Currency } from '@/types/payment'
import { PurchaseType } from '@/types/payment-types'
import { PurchaseState } from '@/types/global'

import { StyledModal } from './styles'
import { ACTIONS_TEXT } from './constants'

interface PurchaseIntentStatusModalProps {
  isOpen: boolean
  onClose(): void
  type: PurchaseType
  amount: string | number
  currency: Currency
  state: PurchaseState
  showActions?: boolean
}

export default function PurchaseIntentStatusModal({
  isOpen,
  onClose,
  type,
  amount,
  currency,
  state,
  showActions = false,
}: PurchaseIntentStatusModalProps) {
  const { user } = useUser()

  const renderStatusComponent = () => {
    if (type) {
      return <OfferStatus amount={amount} currency={currency} state={state} />
    }

    return <Text>Comming soon</Text>
  }

  return (
    <StyledModal
      centered
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      onClose={onClose}
    >
      {renderStatusComponent()}
      {showActions && (
        <Stack direction='row' justifyContent='flex-end' gap={2}>
          <Button.Tertiary onClick={onClose}>
            {ACTIONS_TEXT.CONTINUE}
          </Button.Tertiary>
          <Link href={`/@${user.profile_url}/activity`} onClick={onClose}>
            <Button.Primary>{ACTIONS_TEXT.GO_TO_PROFILE}</Button.Primary>
          </Link>
        </Stack>
      )}
    </StyledModal>
  )
}
