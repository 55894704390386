export const SUCCESS_MESSAGE_CONSTANTS = {
  TITLE: 'Offer Successfully Made 🎉',
  BODY1: 'Congratulations! You have successfully made an offer on this Nifty.',
  BODY2: 'You can see the status of your offer on your dashboard.',
  BUTTON_TEXT: 'View Offers Made',
} as const

export const GLOBAL_OFFER_INFO_CONSTANTS = {
  TITLE: 'Make Global Offer',
  BODY: 'A global offer will show to all holders of any edition of this NFT.',
} as const

export const GLOBAL_OFFER_PAGE_CONSTANTS = {
  TITLE: 'Make a Global Offer for',
  TITLE_CAPTION:
    'A global offer is an offer to purchase ANY edition of a particular Nifty at a certain price.',
  TRAITS_HEADING: 'Select Traits (optional)',
  OFFER_PLACEHOLDER: 'Offer Amount',
} as const
