import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { BannerItem, Link as NiftyLink } from "@/elements/src";
import { CTABannerInfo } from "@/types/api_types";

export const DynamicBanner = (
  bannerInfo: CTABannerInfo,
  logEvent: unknown
): BannerItem => ({
  message: (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        width: "calc(100% - 45px)",
      }}
    >
      <Typography
        sx={{
          color: bannerInfo?.main_text_color,
          fontWeight: 700,
          fontSize: 16,
        }}
      >
        {bannerInfo?.main_text}{" "}
      </Typography>
      {bannerInfo?.button_link !== "none" && (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          href={bannerInfo?.button_link}
          component={NiftyLink}
          style={{
            color: bannerInfo?.button_text_color,
            backgroundColor: bannerInfo?.button_background_color,
            cursor: "pointer",
          }}
          onClick={() => {
            if (typeof logEvent === "function")
              logEvent?.(`${bannerInfo.banner_name} Clicked`, "Navigation", {
                urlPath: bannerInfo?.button_link,
              });
          }}
        >
          {bannerInfo?.button_text}
        </Button>
      )}
    </Grid>
  ),
  localStorageKey: bannerInfo?.banner_name,
  background: bannerInfo?.main_background_color,
  backgroundImage: "",
});

export default DynamicBanner;
