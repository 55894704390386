import { InvalidQuantityMessage } from '@/elements/src'
import { useCallback, useEffect, useState } from 'react'
import {
  usePrimaryMarketPurchaseIntent,
  useUpdatePrimaryMarketPurchaseIntent,
} from '@/hooks/usePurchaseIntent'
import { useRouter } from 'next/navigation'
import useDefaultPaymentMethod from '@/hooks/useDefaultPaymentMethod'
import {
  getDefaultPaymentCurrency,
  PaymentApiError,
  PaymentCurrency,
  PaymentMethod,
} from '@/types/payment-types'
import { NiftyConfig } from '../_components/DrawingCheckout'
import { UnmintedNiftyMetadata } from '@/types/global'
import useDialog from '@/hooks/useDialog'

type UrlParams = {
  contractAddress: string
  niftyType: string
}
const redirectToItemDetail = (
  { contractAddress, niftyType }: UrlParams,
  router: ReturnType<typeof useRouter>,
) =>
  setTimeout(() => {
    router.replace(`/itemdetail/primary/${contractAddress}/${niftyType}`)
  }, 4000)


interface UseDrawingCheckoutArgs {
  purchaseIntentId: string,
  niftyConfig: NiftyConfig,
  nifty?: UnmintedNiftyMetadata,
  openDialog: ReturnType<typeof useDialog>['openDialog'],
  onPaymentError?: (err?: unknown) => void,
  onPurchaseIntentStatus?: (status: 'init' | 'success' | 'error' | null) => void,
  preventRedirect?: boolean
}

export default function useDrawingCheckout({
  purchaseIntentId,
  niftyConfig,
  nifty,
  openDialog,
  onPaymentError,
  onPurchaseIntentStatus,
  preventRedirect = false,
}: UseDrawingCheckoutArgs & { nifty?: UnmintedNiftyMetadata, niftyConfig: NiftyConfig }) {
  const dropType = nifty?.primary_sale_type
  const { purchaseIntent, stripePaymentIntent, paymentRequired, refetch } =
    usePrimaryMarketPurchaseIntent(purchaseIntentId, { dropType })

  const { updatePurchaseIntent, isLoading: isUpdating } =
    useUpdatePrimaryMarketPurchaseIntent()

  const { defaultPaymentMethod } = useDefaultPaymentMethod()
  const [paymentMethod, setPaymentMethod] =
    useState<PaymentMethod>(defaultPaymentMethod)
  const [paymentCurrency, setPaymentCurrency] = useState<PaymentCurrency>(
    getDefaultPaymentCurrency(defaultPaymentMethod),
  )

  const [quantity, setQuantity] = useState(
    purchaseIntent?.requestedQuantity ?? 1,
  )
  const quantityHasChanged = purchaseIntent?.requestedQuantity !== quantity

  const [purchaseComplete, setPurchaseComplete] = useState(false)
  
  const shouldUpdatePurchaseIntent =
    !purchaseComplete &&
    !isUpdating &&
    (!!paymentRequired || quantityHasChanged) &&
    purchaseIntent?.state === 'INITIATED' &&
    // TODO: Add update condition(s) for destination changes
    // the payment method has changed
    (paymentMethod !== purchaseIntent?.paymentMethod ||
      // OR the payment currency has changed
      paymentCurrency !== purchaseIntent?.paymentCurrency ||
      // OR paymentMethod is a Stripe payment method, AND we don't have a Stripe PI
      ([PaymentMethod.BNPL, PaymentMethod.CARD].includes(paymentMethod) &&
        !stripePaymentIntent))

  // #region error handling
  const router = useRouter()
  const [updateFailed, setUpdateFailed] = useState(false)
  const [maxQuantityOverride, setMaxQuantityOverride] = useState<number>()

  const handlePurchaseIntentError = useCallback(
    (e: PaymentApiError) => {
      const { errorType, message, allowedQuantity } = e ?? {}
      switch (errorType) {
        case 'invalid_requested_quantity': {
          if ((allowedQuantity ?? 0) < 1) {
            setUpdateFailed(true)
            // Sold out -- nothing to do except show error message and redirect
            openDialog(
              <InvalidQuantityMessage
                allowedQuantity={0}
                purchaseType={nifty?.contractObj.template}
              />,
            )
            if (onPaymentError) onPaymentError(e)
            if (!preventRedirect)
              return redirectToItemDetail(
                niftyConfig,
                router,
              )
          }
          setMaxQuantityOverride(allowedQuantity)
          return setQuantity(allowedQuantity ?? 1)
        }
        // User tried to purchase more than currently available, so we need to adjust constraints and re-update
        default: {
          setUpdateFailed(true)
          // TODO: Determine if we're dealing with a fatal error before redirect
          if (onPaymentError) onPaymentError(e)
          if (!preventRedirect)
            redirectToItemDetail(niftyConfig, router)
          return openDialog(
            message ?? (
              <>
                There was a problem creating your purchase.
                <br />
                Redirecting you to the Item Detail page.
              </>
            ),
          )
        }
      }
    },
    [
      nifty,
      niftyConfig,
      openDialog,
      onPaymentError,
      preventRedirect,
      router,
    ],
  )
  // #endregion

  useEffect(() => {
    if (
      !purchaseIntentId ||
      !dropType ||
      !shouldUpdatePurchaseIntent ||
      updateFailed
    )
      return

    if (onPurchaseIntentStatus) onPurchaseIntentStatus('init')

    updatePurchaseIntent(
      {
        dropType,
        id: purchaseIntentId,
        // Don't attempt to set payment method for free drops
        payment_method: paymentRequired ? paymentMethod : PaymentMethod.NONE,
        payment_currency: paymentCurrency,
        requested_quantity: quantity,
        // TODO: Uncomment once external deliverys are supported on PM purchases
        // nifty_delivery_destination:
        //   niftyDestination === NiftyDestination.NIFTY_GATEWAY
        //     ? niftyDestination
        //     : NiftyDestination.EXTERNAL_WALLET,
        // nifty_destination_ext_wallet: utils.isAddress(niftyDestination)
        //   ? niftyDestination
        //   : undefined,
      },
      {
        onSuccess: () => {
          if (onPurchaseIntentStatus) onPurchaseIntentStatus('success')
        },
        onError: (e) => {
          if (onPurchaseIntentStatus) onPurchaseIntentStatus('error')
          handlePurchaseIntentError(e)
        },
      },
    )
  }, [
    shouldUpdatePurchaseIntent,
    purchaseIntentId,
    paymentMethod,
    paymentCurrency,
    paymentRequired,
    updatePurchaseIntent,
    handlePurchaseIntentError,
    updateFailed,
    dropType,
    onPurchaseIntentStatus,
    quantity,
  ])

  const afterSubmit = useCallback(() => {
    setPurchaseComplete(true)
    refetch({ cancelRefetch: true })
  }, [setPurchaseComplete, refetch])

  return {
    nifty,
    paymentRequired,
    afterSubmit,
    updateFailed,
    isUpdating,
    purchaseIntent,
    paymentCurrency,
    setPaymentCurrency,
    paymentMethod,
    setPaymentMethod,
    quantity,
    setQuantity,
    maxQuantityOverride,
  }
}
