'use client'

import Script from 'next/script'

// Loading strategy is set to afterInteractive: Load early but after some hydration on the page occurs.
const GoogleAnalytics = () => {
  return (
    <>
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_ANALYTICS}`}
      />
      <Script
        id='ng-3rdParty--GoogleAnalytics'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            if (window.location.pathname !== '/reset-password') {
              gtag('set', { 'send_page_view': true });
              gtag('config', '${process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_ANALYTICS}');
            } else {
              gtag('set', { 'send_page_view': false });
              gtag('config', '${process.env.NEXT_PUBLIC_REACT_APP_GOOGLE_ANALYTICS}', { 'allow_google_signals': false });
            }
          `,
        }}
      />
    </>
  )
}

export default GoogleAnalytics
