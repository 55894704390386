import { NiftyModalFirebird } from '@/elements/src'
import { type ReturnUrlGeneratorConfig } from '@/types/payment-types'
import { type Currency } from '@/types/payment'
import AuctionCheckout from '@/app/(ng-core)/(auctions)/_components/AuctionCheckout'
import DrawingCheckout from '@/app/(ng-core)/(drawings)/_components/DrawingCheckoutContainer'
import { PaymentsWeb3Provider } from '@/app/(ng-core)/checkout/_components/PaymentsWeb3Provider'
import GlobalOfferCheckout from '@/app/(ng-core)/(offers)/_components/GlobalOfferCheckout'
import OfferCheckout from '@/app/(ng-core)/(offers)/_components/OfferCheckout'

export type CheckoutModalProps = {
  isOpen?: boolean
  onClose: () => void
  dropType?: string
  contractAddress: string
  purchaseIntentId: string
  niftyType: string
  tokenId?: string
  offerType?: 'offer' | 'global_offer'
  preventRedirect?: boolean
  generateReturnUrl?: (config: ReturnUrlGeneratorConfig) => string
  onPaymentSuccess?: (paymentAmount: string, paymentCurrency: Currency) => void
}

function CheckoutModal({
  isOpen = true,
  onClose,
  dropType,
  offerType,
  contractAddress,
  purchaseIntentId,
  niftyType,
  tokenId,
  generateReturnUrl,
  onPaymentSuccess,
  preventRedirect = false,
}: CheckoutModalProps) {
  const renderPrimaryMarketCheckout = (type: string) => {
    const AUCTION_TYPES = ['auction', 'ranked_auction', 'silent_auction']

    if (AUCTION_TYPES.includes(type)) {
      return (
        <AuctionCheckout
          contractAddress={contractAddress}
          niftyType={niftyType}
          purchaseIntentId={purchaseIntentId}
          closeModal={onClose}
          preventRedirect={preventRedirect}
          generateReturnUrl={generateReturnUrl}
          onPaymentSuccess={onPaymentSuccess}
        />
      )
    } else if (type === 'drawing' || type === 'pack') {
      return (
        <DrawingCheckout
          contractAddress={contractAddress}
          niftyType={niftyType}
          purchaseIntentId={purchaseIntentId}
          closeModal={onClose}
          preventRedirect={preventRedirect}
          onPaymentSuccess={onPaymentSuccess}
        />
      )
    }
    return <div>Coming Soon...</div>
  }

  const renderSecondaryMarketCheckout = (type: string) => {
    if (type === 'offer' && tokenId) {
      return (
        <OfferCheckout
          purchaseIntentId={purchaseIntentId}
          contractAddress={contractAddress}
          tokenId={tokenId}
          onClose={onClose}
        />
      )
    }

    return (
      <GlobalOfferCheckout
        contractAddress={contractAddress}
        niftyType={niftyType}
        purchaseIntentId={purchaseIntentId}
        onClose={onClose}
      />
    )
  }

  return (
    <NiftyModalFirebird
      open={isOpen}
      onClose={onClose}
      closeButton={Boolean(onClose)}
      name='checkout-modal'
      width='1060px'
    >
      <PaymentsWeb3Provider>
        {dropType ? renderPrimaryMarketCheckout(dropType) : null}
        {offerType ? renderSecondaryMarketCheckout(offerType) : null}
      </PaymentsWeb3Provider>
    </NiftyModalFirebird>
  )
}

export default CheckoutModal
