import {
  Zap as ZapIcon,
  ShoppingBag as ShoppingIcon,
  Activity as ActivityIcon,
  User as ProfileIcon,
  Grid as DashboardIcon,
  Settings as SettingsIcon,
  HelpCircle as HelpCircleIcon,
  TrendingUp as TrendingUpIcon,
} from 'react-feather'
import { useIntl } from 'react-intl'
import { useAnalytics } from '@/providers/AnalyticsContext'
import useExploreFeatureFlag from '@/hooks/useExploreFeatureFlag'

export const getHomePath = () => {
  return '/'
}

export const paths = {
  home: '/',
  signup: '/signup',
  login: '/login',
  explore: '/explore',
  marketplace: '/marketplace',
  activity: '/sitewide-activity',
  about: '/about',
  contact: '/contact',
  terms: '/termsofuse',
  privacy: '/privacy',
  whatIsAnNFT: '/what-is-an-nft',
  discover: '/discover',
  rankings: '/rankings',
  drops: '/drops',
  niftyCurated: '/publisher/niftygateway_curated',
}

export const externalLinks = {
  gemini: 'https://www.gemini.com/',
  help: 'https://help.niftygateway.com/hc/en-us',
}

// constants
export const useNavbarLinks = () => {
  const intl = useIntl()
  const { logEvent } = useAnalytics()
  const isExplorePageEnabled = useExploreFeatureFlag()

  const handleNavbarItemClick = (itemName: string) => {
    logEvent?.('Navbar Item Clicked', 'Navigation', { navBarItem: itemName })
  }

  const items = [
    {
      label: intl.formatMessage({
        defaultMessage: 'Marketplace',
        id: 'j3FIMR',
        description: 'Link on navbar to go to marketplace page',
      }),
      path: isExplorePageEnabled ? paths.explore : paths.marketplace,
      icon: ShoppingIcon,
      onClick: () => handleNavbarItemClick('Marketplace'),
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Stats',
        id: '92KeX7',
        description: 'Link to open dropdown for stats pages',
      }),
      path: '/',
      icon: ZapIcon,
      menuLinks: [
        {
          label: intl.formatMessage({
            defaultMessage: 'Rankings',
            id: '/c9XXM',
            description: 'Link on navbar to go to rankings page',
          }),
          path: paths.rankings,
          icon: TrendingUpIcon,
          onClick: () => handleNavbarItemClick('Rankings'),
        },
        {
          label: intl.formatMessage({
            defaultMessage: 'Activity',
            id: '/v/1Yg',
            description: 'Link on navbar to go to activity page',
          }),
          path: paths.activity,
          icon: ActivityIcon,
          onClick: () => handleNavbarItemClick('Activity'),
        },
      ],
    },
  ]

  return items
}

export const useUserLinks = (user: {
  userProfileUrl: string
  userName: string
}) => {
  const intl = useIntl()

  return [
    {
      label: intl.formatMessage({
        defaultMessage: 'Profile',
        id: 'w1UUHf',
        description: 'Profile link label on navbar',
      }),
      path: `/@${user.userName}`,
      icon: ProfileIcon,
      noMobile: true,
      testid: 'navbar-profile',
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Dashboard',
        id: 'mqut5h',
        description: 'Nifty Dasboard link label on navbar',
      }),
      path: '/dashboard',
      icon: DashboardIcon,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Account Settings',
        id: 'mLdGF0',
        description: 'Account Settings link label on navbar',
      }),
      path: '/account',
      icon: SettingsIcon,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Help center',
        id: 'tYLvb5',
        description: 'Help Center link label on navbar',
      }),
      path: externalLinks.help,
      icon: HelpCircleIcon,
    },
  ]
}

const links = {
  useNavbarLinks,
  useUserLinks,
  paths,
  externalLinks,
}

export default links
