/* eslint-disable react/display-name */
import React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { InputBaseComponentProps } from '@mui/material'

const DollarNumberFormat = React.forwardRef<
  React.ElementType,
  InputBaseComponentProps
>((props, ref) => {
  const { defaultValue, name, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      defaultValue={defaultValue as NumberFormatProps['defaultValue']}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange?.({
          target: {
            name,
            value: values.value,
          },
        } as React.ChangeEvent<HTMLInputElement>)
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      suffix=' USD'
      prefix='$'
      allowNegative={false}
    />
  )
})

export default DollarNumberFormat
