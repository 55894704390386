import { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'

import { Box, Skeleton, Stack, Typography } from '@mui/material'
import { Money, NiftyCard } from '@/elements/src'

import useNiftyType from '@/hooks/useNiftyType'
import {
  useOfferPurchaseIntent,
  useUpdateOfferPurchaseIntent,
} from '@/hooks/usePurchaseIntent'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import useCurrentNiftyEdition from '@/hooks/useCurrentNiftyEdition'

import { translatePaymentCurrency } from '@/types/payment-types'
import getDropCreator from '@/utils/get-drop-creator'
import getToastifyOptions from '@/utils/toastify-options'

import OfferForm from './OfferForm'
import PurchaseIntentStatusModal from '../../(auctions)/_components/PurchaseIntentStatusModal'
import { OfferFormData } from '../utils'

interface OfferCheckoutProps {
  contractAddress: string
  tokenId: string
  purchaseIntentId: string
  onClose(): void
}

export default function OfferCheckout({
  contractAddress,
  tokenId,
  purchaseIntentId,
  onClose,
}: OfferCheckoutProps) {
  const [successOfferData, setSuccessOfferData] =
    useState<OfferFormData | null>(null)
  const { data: amno, isLoading: isLoadingAmno } = useCurrentNiftyEdition({
    contractAddress,
    tokenId,
  })
  const { data: nbno } = useNiftyType({
    contractAddress,
    niftyType: amno?.unmintedNiftyObjThatCreatedThis.niftyType,
  })
  const {
    purchaseIntent,
    stripePaymentIntent,
    isLoading: isLoadingPurchaseIntent,
    refetch: refetchOfferPurchaseIntent,
  } = useOfferPurchaseIntent(purchaseIntentId)
  const { updatePurchaseIntent, isLoading: isUpdatingPurchaseIntent } =
    useUpdateOfferPurchaseIntent()

  const isMobile = useMediaQueryState((theme) => theme.breakpoints.down('md'))

  return (
    <Stack direction='row' spacing={8} px={5} py={4}>
      <Stack direction='row'>
        <Box
          sx={{
            width: isMobile ? '300px' : '100%',
            maxWidth: 386,
            height: 'fit-content',
            padding: '12px',
            border: '1px solid rgba(230, 230, 230, 1)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderRadius: '16px',
            flexShrink: 0,
          }}
        >
          <div
            id='inner-image-container'
            style={{
              width: isMobile ? '250px' : '360px',
              height: isMobile ? '250px' : '360px',
              position: 'relative',
              marginBottom: 12,
              borderRadius: 16,
              overflow: 'hidden',
            }}
          >
            {!isEmpty(amno) && !isEmpty(nbno) ? (
              <NiftyCard
                asset={{
                  src: amno.image_url,
                  previewSrc: amno.image_preview_url,
                  staticSrc: nbno.staticThumbnailUrl,
                  htmlSource: nbno.niftyHtmlMetadataUrl,
                  alt: amno.name,
                  assetProps: nbno.threeDimensionalProps?.asset_props,
                }}
              />
            ) : (
              <Skeleton
                variant='rectangular'
                animation='wave'
                width={200}
                height={200}
              />
            )}
          </div>
          {!isEmpty(amno) && (
            <div
              style={{
                alignContent: 'flex-start',
              }}
            >
              <Typography
                sx={{
                  fontSize: isMobile ? 15 : 20,
                  fontWeight: 600,
                  textWrap: 'wrap',
                }}
              >
                {amno.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: isMobile ? 12 : 16,
                  fontWeight: 400,
                  color: 'rgba(1, 3, 4, 0.6)',
                  marginTop: '8px',
                }}
              >
                {getDropCreator(amno.creators ?? [])}
              </Typography>
            </div>
          )}
        </Box>
      </Stack>
      <Stack>
        {amno && (
          <OfferForm
            itemTitle={amno.name}
            isCollectingTax={nbno?.is_collecting_tax}
            isLoading={isLoadingAmno || isLoadingPurchaseIntent}
            isUpdating={isUpdatingPurchaseIntent}
            onUpdatePurchaseIntent={(data) => {
              updatePurchaseIntent(data)
            }}
            onSuccess={(formData) => {
              refetchOfferPurchaseIntent({ cancelRefetch: true })

              setSuccessOfferData(formData)
            }}
            purchaseIntent={purchaseIntent}
            stripePaymentIntent={stripePaymentIntent}
          />
        )}
      </Stack>
      {successOfferData && (
        <PurchaseIntentStatusModal
          showActions
          type='OFFER'
          state='AUTHORIZED'
          amount={successOfferData.offerAmount}
          currency={translatePaymentCurrency(successOfferData.offerCurrency)}
          isOpen={Boolean(successOfferData)}
          onClose={() => {
            toast.success(
              <>
                Your&nbsp;
                <strong>
                  <Money
                    value={[
                      successOfferData.offerAmount,
                      translatePaymentCurrency(successOfferData.offerCurrency),
                    ]}
                    convertFromCents={false}
                  />
                </strong>
                &nbsp;offer for <strong>{nbno?.niftyTitle}</strong> was
                successfully submitted!
              </>,
              getToastifyOptions('success'),
            )

            onClose()
            setSuccessOfferData(null)
          }}
        />
      )}
    </Stack>
  )
}
