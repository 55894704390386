"use client";

import React, { useCallback, useEffect, useState } from "react";
import { getLoginRedirect } from "@/utils/nifty-utils";
import FullStoryService from "@/services/FullStoryService";
import { UserIdentity } from "@/types/user-identity";
import { CustomErrorApiResponse } from "@/types/api_types";
import { useAppDispatch } from "@/redux/store";
import { setUserId } from "@/app/(ng-core)/(publishers)/publisher/_redux-slices/auth";
import UserContext, { initialState } from "./UserContext";
import useAuth from "@/hooks/useAuth";
import { useAnalytics } from "./AnalyticsContext";
import usePublisherPermissions from "@/hooks/usePublisherPermissions";
import { getUserInfo, getUserPurchaseInfo } from "@/api/auth";

// legacy support
import {
  setAccessToken,
  setRefreshToken,
  setAccessTokenExpirationDate,
  setUserEmailAddress,
  setUserProfileURL,
  setUserGlobalID,
} from "@/redux/actions";
import { User, AuthCredentials } from "@/types/global";

type UserProfileResponse = {
  didSucceed: boolean;
  errorType: string;
  message: string;
  userProfile: User;
};

type UserPublisherPermissionsResponse = {
  results: {
    id: number;
    name: string;
    slug: string;
  }[];
};

type UserPurchaseInfoResponse = {
  cumulative_lifetime_spend: number;
  has_purchased: boolean;
  number_of_nifties_purchased: number;
};

type UserApiKey =
  | "userProfile"
  | "userPublisherPermissions"
  | "userPurchaseInfo";

type UserPromiseResponse = {
  api: UserApiKey;
  payload:
    | UserProfileResponse
    | UserPublisherPermissionsResponse
    | UserPurchaseInfoResponse;
};

const LOCAL_STORAGE_USER_INFO_KEY = "userProfileBackup";
const LOCAL_STORAGE_USER_PURCHASES_KEY = "userPurchasesBackup";

const hasBackupUserInfo = () =>
  !!localStorage.getItem(LOCAL_STORAGE_USER_INFO_KEY);

const getUserProfilePromise = (
  flushCredentials: { (): void; (): void },
  updateUser: {
    (value: React.SetStateAction<User>): void;
  },
  user: User,
  authCred: AuthCredentials
) => {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const { data } = await getUserInfo(authCred.access_token);
        // Insert into local storage so we have something to fall back to later
        localStorage.setItem(
          LOCAL_STORAGE_USER_INFO_KEY,
          JSON.stringify(data.userProfile)
        );

        resolve({ payload: data, api: "userProfile" });
      } catch (error: unknown) {
        // For 500-series errors, load previously-saved data if present
        if (
          error instanceof Error &&
          ((error as CustomErrorApiResponse)?.statusCode ?? 0) >= 500 &&
          hasBackupUserInfo()
        ) {
          const storedUserValue = localStorage.getItem(
            LOCAL_STORAGE_USER_INFO_KEY
          );
          const userProfile = JSON.parse(storedUserValue!) as User;
          return resolve({ payload: { userProfile }, api: "userProfile" });
        }
        // Flush on unauthorized
        if (
          error instanceof Error &&
          (((error as CustomErrorApiResponse)?.statusCode ?? 0) === 401 ||
            ((error as CustomErrorApiResponse)?.statusCode ?? 0) === 403)
        ) {
          flushCredentials();
          const redirectLocation = getLoginRedirect(
            window.location.pathname,
            window.location.search
          );
          updateUser({ ...user, notLoggedIn: true, isLoading: false });
          FullStoryService.stopSessionRecording();
          window.location.replace(redirectLocation);
        }

        reject(error);
      }
    })();
  });
};

const getUserPurchaseInfoPromise = (authCred: AuthCredentials) => {
  return new Promise((resolve, reject) => {
    (async () => {
      const { data } = await getUserPurchaseInfo(authCred.access_token);

      // Insert into local storage so we have something to fall back to later
      localStorage.setItem(
        LOCAL_STORAGE_USER_PURCHASES_KEY,
        JSON.stringify(data)
      );

      resolve({ payload: data, api: "userPurchaseInfo" });
    })();
  });
};

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const [user, updateUser] = useState(initialState);

  // get auth state
  const auth = useAuth();
  const {
    isLoggedIn,
    hasToRefreshToken,
    isRefreshing,
    lastRefreshFailed,
    values,
  } = auth;

  const {
    data: publisherPermissions = [],
    isLoading: isLoadingPublisherPermissions,
    remove,
  } = usePublisherPermissions();

  const { setUserIdentity } = useAnalytics();

  const flushCredentials = () => {
    auth.update?.({ credentials: {} });
    // legacy
    dispatch(setAccessToken(null));
    dispatch(setRefreshToken(null));
    dispatch(setAccessTokenExpirationDate(null));
  };

  const setUserProfileInfo = useCallback(
    (profile: User, purchaseInfo?: UserPurchaseInfoResponse) => {
      const userIdentity: UserIdentity = {
        name: profile.name,
        firstName: profile.first_name ?? "",
        lastName: profile.last_name ?? "",
        email: profile.user_email,
        countryCode: profile.country_code,
        phone: profile.phone_number,
        twoFactorEnabled: Boolean(profile.twofa_enabled),
        consumerid: String(profile.id),
        hasPurchased: purchaseInfo?.has_purchased ?? false,
        numberOfNiftiesPurchased:
          purchaseInfo?.number_of_nifties_purchased ?? 0,
        totalAmountPurchased: purchaseInfo?.cumulative_lifetime_spend ?? 0,
      };

      if (setUserIdentity) {
        setUserIdentity(userIdentity);
      }
      FullStoryService.setUserIdentity(userIdentity);
      FullStoryService.startSessionRecording();

      dispatch(setUserEmailAddress(profile.user_email));
      dispatch(setUserProfileURL(profile.profile_url));
      dispatch(setUserGlobalID(profile.id));
      dispatch(setUserId(profile.user_id));

      updateUser({
        ...initialState,
        ...profile,
        notLoggedIn: false,
        isLoading: false,
      });
    },
    [dispatch, setUserIdentity, updateUser]
  );

  const loadUserProfileFromLocalStorage = useCallback(() => {
    const storedUserValue = localStorage.getItem(LOCAL_STORAGE_USER_INFO_KEY);
    const storedPurchasesValue = localStorage.getItem(
      LOCAL_STORAGE_USER_PURCHASES_KEY
    );
    if (!storedUserValue) return;

    const userProfileInfo = JSON.parse(storedUserValue) as User;
    const purchaseInfo = !storedPurchasesValue
      ? undefined
      : (JSON.parse(storedPurchasesValue) as UserPurchaseInfoResponse);
    setUserProfileInfo(userProfileInfo, purchaseInfo);
  }, [setUserProfileInfo]);

  const fetchUser = async () => {
    const responses = await Promise.allSettled([
      getUserProfilePromise(
        flushCredentials,
        // @ts-ignore
        updateUser,
        user,
        values.credentials
      ),
      getUserPurchaseInfoPromise(values.credentials),
    ]);

    const responsesObj = responses.reduce((accum, curValue) => {
      if (curValue?.status === "fulfilled") {
        const value = curValue?.value as UserPromiseResponse;
        const key = value.api;

        if (!accum[key]) {
          // eslint-disable-next-line no-param-reassign
          accum[key] = value;
        }
      }

      return accum;
    }, {} as Record<UserApiKey, UserPromiseResponse>);

    if (responsesObj?.userProfile?.payload) {
      const profile = (responsesObj.userProfile.payload as UserProfileResponse)
        .userProfile;
      const purchaseInfo = responsesObj?.userPurchaseInfo?.payload as
        | UserPurchaseInfoResponse
        | undefined;

      setUserProfileInfo(profile, purchaseInfo);
    } else {
      updateUser({ ...user, notLoggedIn: true, isLoading: false });
    }
  };

  useEffect(() => {
    if ((!hasToRefreshToken || lastRefreshFailed) && !isRefreshing) {
      if (isLoggedIn()) {
        fetchUser();
      } else if (
        lastRefreshFailed &&
        !!values.credentials.refresh_token &&
        hasBackupUserInfo()
      ) {
        // If refresh attempt failed, fall back to profile info from local storage if available
        loadUserProfileFromLocalStorage();
      } else {
        updateUser({ ...user, notLoggedIn: true, isLoading: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoggedIn(),
    hasToRefreshToken,
    isRefreshing,
    loadUserProfileFromLocalStorage,
  ]);

  useEffect(() => {
    const handleNewWindow = (event: StorageEvent) => {
      if (
        event.key === "auth.credentials" &&
        event.newValue !== event.oldValue
      ) {
        // TODO: react-query refactor: invalidate queries instead of reloading?
        // location.reload(); // disabling page reload when we logout
      }
    };
    window.addEventListener("storage", handleNewWindow);

    return () => {
      window.removeEventListener("storage", handleNewWindow);
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        publisherPermissions,
        isLoadingPublisherPermissions,
        remove,
        // @ts-ignore
        update: updateUser,
        refresh: fetchUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
