export const PageNiftyTypeKey = 'PageNiftyTypeKey'

export const RankedAuctionBidHistoryQueryKey = 'RankedAuctionBidHistoryQueryKey'

export const RankedAuctionMaxBidQueryKey = 'RankedAuctionMaxBidQueryKey'

export const DrawingQueryKey = 'DrawingQueryKey'

export const ParticipationEligibilityQueryKey =
  'ParticipationEligibilityQueryKey'

export const CriteriaQueryKey = 'CriteriaQueryKey'

export const BuyCriteriaQueryKey = 'BuyCriteriaQueryKey'

export const BID_HISTORY_LIMIT = 20

export const VALID_BTN_TEXT_NOT_ELIGIBLE = [
  'mint',
  'buy',
  'enter drawing',
  'place bid',
  'claim',
]

export const BACK_TO_DROP_TEXT = 'Back to Drop'

export const REQUIRES_VALIDATION_TO_PARTICIPATE_MSG =
  'This drop requires account validation to participate'

export const PAYMENT_OPTIONS_TITLE = 'Payment Options:'

export const PAYMENT_OPTIONS = [
  'Credit Card',
  'Prepaid ETH',
  'Nifty Gateway Balance',
  'Gemini Balance',
]

// Used to check if we need to redirect off of the item detail page to the drop page
export const DRIVE_LAP_2_CONTRACT = '0x4034d0d995f204de3a025ab0b1b636d0a543171b'
