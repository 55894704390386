import { BaseChain, Creator } from "./collection";
import { NiftyTypeMetadata } from "./global";

export enum Category {
  Art = "Art",
  Social = "Social",
  Collectibles = "Collectibles",
  Gaming = "Gaming",
  Style = "Style",
}

export enum ListingType {
  curated = "curated",
  verified = "verified",
}

export enum PartnerTier {
  Tier1 = "Tier1",
  Tier2 = "Tier2",
}

// Sorting
export enum SortBy {
  lowest = "lowest",
  highest = "highest",
  recent = "recent",
  oldest = "oldest",
  popular = "popular",
  lastSold = "lastSold",
  lowestEdition = "lowestEdition",
  highestEdition = "highestEdition",
  lowestMass = "lowestMass",
  highestMass = "highestMass",
  mostLiked = "mostLiked",
  highestLastSale = "highestLastSale",
  rarityHightoLow = "rarityHightoLow",
  rarityLowtoHigh = "rarityLowtoHigh",
}

export enum SortType {
  price = "price",
  editionPrice = "price_in_cents",
  lastSoldTime = "last_sold_time",
  lastBidTime = "last_bid_time",
  editionNumber = "edition",
  oldestListTime = "oldest_list_time",
  lastSaleTime = "lastSaleTime",
  lastSoldAmount = "last_sold_amount",
  mass = "pak_merge_mass_trait",
  likes = "likes",
  rarity = "rarity_score",
}

export enum SortOrder {
  ascending = "asc",
  descending = "desc",
}

export type SortOption = {
  label: string;
  sort: [SortType, SortOrder] | undefined;
};

export type MarketplaceState = {
  sortBy: SortBy | null | undefined;
  contractAddress: string | null;
  niftyType: string | null;
  tokenId: string | null;
  currentPage: string;
  filters?: {
    onSale?: boolean | null;
    oneOfOne?: boolean | null;
    acceptingOffers?: boolean | null;
    tokenId?: string | null;
  };
  categoryName?: { [key: string]: boolean | null | any };
  categories?: { [key: string]: boolean | null | any };
  partnerTier?: {
    [key in PartnerTier]?: boolean | null;
  };
  listingType?: {
    [key in ListingType]?: boolean | null;
  };
  baseChain?: {
    [key in BaseChain]?: boolean | null;
  };
  traits?: { [key: string]: { [key: string]: boolean | null } };
  price?: {
    min?: string | null;
    max?: string | null;
  };
  mass?: {
    min?: string | null;
    max?: string | null;
  };
  editionSize?: string | null;
  hotDeals?: string | null;
  trending?: string | null;
};

export type MarketplaceRouterParams = {
  artistId?: string;
  artistSlug?: string;
  contractAddress?: string;
  collectionSlug?: string;
  niftyType?: string;
  tokenId?: string;
  publisherId?: string;
  publisherSlug?: string;
};

export type MarketplaceSearchQuery = {
  currentPage?: number | null;
  sortBy?: SortBy | null;
  filters?: {
    onSale?: boolean | null;
    tokenId?: string | null;
    acceptingOffers?: boolean | null;
  };
  categoryName?: { [key: string]: boolean | null | undefined };
  categories?: {
    [key: string]: boolean | null | undefined;
  };
  partnerTier?: {
    [key in PartnerTier]?: boolean | null;
  };
  listingType?: {
    [key in ListingType]?: boolean | null;
  };
  baseChain?: {
    [key in BaseChain]?: boolean | null;
  };
  traits?: {
    [key: string]: {
      [key: string]: boolean | null;
    };
  };
  price?: {
    min?: number | null;
    max?: number | null;
  };
  mass?: {
    min?: string | null;
    max?: string | null;
  };
  editionSize?: number | null;
};

export type MarketplaceSearchResult = {
  slug?: string;
  profile_pic_url?: string;
  name?: string;
  artistId?: number;
  profileImageUrl?: string;
  profileUrl?: string;
  storeName?: string;
  projectIcon?: string;
  projectCategory?: string;
  contractAddress?: string;
};

export type UpdateQueryFunction = (
  obj: MarketplaceSearchQuery,
  clearRest?: boolean
) => void;

export const MarketplaceEmptyState = {
  sortBy: null,
  currentPage: "1",
  contractAddress: null,
  niftyType: null,
  tokenId: null,
  editionSize: null,
  hotDeals: null,
  trending: null,
  filters: {},
  listingType: {},
  contentType: {},
  baseChain: {},
  categoryName: {},
  categories: {},
  partnerTier: {},
  traits: {},
  price: {
    min: null,
    max: null,
  },
  mass: {
    min: null,
    max: null,
  },
};

export type MoreFromCollectionItemType = {
  asset: {
    src: string;
  };
  contractAddress: string;
  isLive: boolean;
  likes: number;
  name: string;
  baseChain: string;
  niftyType: number;
  creators: Creator[];
  numberOfEditions: number;
  lowestAskingPrice: NiftyTypeMetadata["floorPrice"];
};
