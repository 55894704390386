import React from 'react'
import { AccordionDetails, AccordionSummary } from '@mui/material'
import NiftyAccordion from '@/elements/src/NiftyAccordion'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconHelpOutlined } from '@hubble/icons'
import { Currency } from '@/types/payment'
import MinBidsCalculation from '../MinBidsCalculation/MinBidsCalculation'
import { colors } from '..'

interface MinBidsCalculationContainerProps {
  minBid: number
  bidCurrency: Currency
  highestBid: number
  bidIncrement: number
}

const MinBidsCalculationContainer = ({
  minBid,
  highestBid,
  bidCurrency,
  bidIncrement,
}: MinBidsCalculationContainerProps) => {
  return (
    <NiftyAccordion
      sx={{
        backgroundColor: colors.lightGrey
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='ranking-simulator-content'
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            columnGap: 8,
          }}
        >
          <IconHelpOutlined />
          <span style={{ fontWeight: 600, fontSize: 14 }}>
            How are minimum bids calculated?
          </span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <MinBidsCalculation
          minBid={minBid}
          bidCurrency={bidCurrency}
          highestBid={highestBid}
          bidIncrement={bidIncrement}
        />
      </AccordionDetails>
    </NiftyAccordion>
  )
}

export default MinBidsCalculationContainer
