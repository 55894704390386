import { Accordion, styled } from '@mui/material'

const NiftyAccordion = styled(Accordion)(() => ({
  '&.MuiAccordion-root': {
    boxShadow: 'none',
  },
  '&.MuiAccordion-root::before': {
    display: 'none',
  },
  '.MuiAccordionSummary-root': {
    minHeight: 0,
  },
  '.MuiAccordionSummary-content': {
    color: '#010304',
    opacity: '.75',
  },
  '.MuiAccordionDetails-root': {
    paddingTop: 0,
  },
  '.MuiAccordionDetails-root>div:first-child': {
    paddingTop: 10,
    borderTop: '1px solid #E6E6E6',
  },
}))

export default NiftyAccordion
