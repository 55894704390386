import querystring from './querystringDetector'
import navigator from './navigatorDetector'
import localstorage from './localstorageDetector'

import { DetectorOptions } from './types'

const lenguageDetectors = {
  querystring,
  navigator,
  localstorage,
}

interface LanguageDetectorOptions extends DetectorOptions {
  order: (keyof typeof lenguageDetectors)[]
}

export default function detectLanguage({
  order,
  ...options
}: LanguageDetectorOptions) {
  const detected: string[] = []

  order.forEach((detectorName) => {
    const result = lenguageDetectors[detectorName]?.detect(options)

    if (result) detected.push(result)
  })

  return detected[0]
}
