import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

export default function useExploreFeatureFlag() {
  const [actualFlagState, setActualFlagState] = useState(
    process.env.NEXT_PUBLIC_REACT_APP_FLAG_EXPLORE_PAGE === 'true',
  )
  const [localFlagState] = useLocalStorage('FLAG_EXPLORE_PAGE', actualFlagState)

  useEffect(() => {
    if (typeof localFlagState !== 'undefined' && !actualFlagState) {
      setActualFlagState(localFlagState)
    }
  }, [localFlagState])

  return actualFlagState
}
