import React from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  List,
  ListItemButton,
  ListSubheader,
  Typography,
  styled,
} from "@mui/material";
import { Avatar, colors } from "@/elements/src";
import { getRecentSearch } from "./search-storage";

const StyledBox = styled(Box)`
  display: flex;
  padding: 0 12px;
  flex-direction: column;

  .searchrecents--list {
    margin: 0 5px;
    background: white;
  }

  .searchrecents--subList {
    padding-left: 0;
    padding-right: 0;
  }

  .searchrecents--card {
    width: 100%;
    background: none;
  }

  .searchrecents--cardHeader {
    padding: 0;
    border-radius: 0;
  }

  .searchrecents--nifty {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
`;

type SearchRecentsProps = {
  onClickResult: ({
    itemPath,
    itemType,
    itemName,
  }: {
    itemPath: string;
    itemType: string;
    itemName: string;
  }) => void;
};

function SearchRecents({ onClickResult }: SearchRecentsProps) {
  const recentSearches = getRecentSearch();

  return (
    <>
      <ListSubheader color="inherit">
        <Typography variant="h4" gutterBottom sx={{ color: colors.niftyBlack }}>
          Recent searches
        </Typography>
      </ListSubheader>
      <StyledBox>
        <List component="div" className="searchrecents--list">
          {recentSearches.map((item, index) => (
            <>
              <ListItemButton
                key={item.id}
                className="searchrecents--subList"
                onClick={() =>
                  onClickResult({
                    itemPath: item.path,
                    itemType: item.type,
                    itemName: item.display_name || item.store_name || "",
                  })
                }
              >
                <Card
                  elevation={0}
                  style={{ borderRadius: 0 }}
                  classes={{ root: "searchrecents--card" }}
                >
                  <CardHeader
                    className="searchrecents--cardHeader"
                    avatar={
                      <Avatar
                        sx={{ height: 40, width: 40 }}
                        src={item.profile_picture_url || item.project_icon}
                        classes={
                          item.project_icon
                            ? { root: "searchrecents--nifty" }
                            : {}
                        }
                      />
                    }
                    title={
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        color={colors.niftyBlack}
                      >
                        {item.display_name || item.store_name}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        fontWeight={400}
                        variant="subtitle1"
                        color={colors.grayText}
                      >
                        <span>{item.type}</span>
                        <span> &bull; </span>
                        <span>{`@${item.slug}`}</span>
                      </Typography>
                    }
                  />
                </Card>
              </ListItemButton>
              {recentSearches.length - 1 !== index && <Divider />}
            </>
          ))}
        </List>
      </StyledBox>
    </>
  );
}

export default SearchRecents;
