'use client'

import React from 'react'
import { HubbleProvider } from '@hubble/web'

// To Suppress HubbleProvider: No translation found error in console
// https://github.com/gemini/hubble-design-system/blob/main/packages/web/.storybook/decorators.tsx#L47

const locale = {
  SearchInput: {
    defaultLabel: 'Search',
    clearButton: 'Remove value',
  },
}

type ProvidersProps = {
  children: React.ReactNode
}

const Provider = ({ children }: ProvidersProps) => {
  return <HubbleProvider locale={locale}>{children}</HubbleProvider>
}

export default Provider
