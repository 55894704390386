import { LEGACY_URL } from '@/app/constants'
import { Checkbox, Link } from '@/elements/src'
import React, { InputHTMLAttributes } from 'react'
import { acceptTermsAndConditions } from '../_api/termsAndConditions'

interface TermsAndConditionsCheckboxProps {
  onChange: (value: boolean) => void
  isChecked: boolean
}
const TermsAndConditionsCheckbox = ({
  onChange,
  isChecked,
}: TermsAndConditionsCheckboxProps) => {
  const label = () => (
    <>
      I agree to Nifty Gateway&apos;s updated{' '}
      <Link href={LEGACY_URL.TERMS} target='_blank' rel='noopener noreferrer'>
        Terms and Conditions
      </Link>
      .
    </>
  )

  const handleOnChange = ({ checked }: HTMLInputElement) => {
    if (checked) {
      // POST terms and conditions agreement
      acceptTermsAndConditions().catch(() => {})
    }
    onChange(checked)
  }
  return (
    <Checkbox
      inputProps={
        {
          'data-testid': 'terms-checkbox',
        } as InputHTMLAttributes<HTMLInputElement>
      }
      label={label()}
      name='Terms and Conditions Agreement'
      onChange={(e) => handleOnChange(e.target)}
      checked={isChecked}
    />
  )
}
export default TermsAndConditionsCheckbox
